import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, StackingColumnSeries, DateTime, Inject, Legend, Category, Tooltip, DataLabel, BarSeries, LineSeries, ColumnSeries, AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation, AccumulationAnnotation } from '@syncfusion/ej2-react-charts';
//import { HtmlEditor, Image, Inject, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Link } from 'react-router-dom';
import ColorLegend from '../ColorLegend';

export class AdminDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Status: 0,
            primaryXAxis: { title: 'Days', interval: 1, valueType: 'DateTime', labelRotation: -45, },
            primaryyAxis: { title: 'Estimates Per Day', rangePadding: 'None', labelFormat: 'value' },
            data2: [],
            data3: [],
            editData: [], loading: true,
        };
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Administration";
        this.loadData();
    }

    generateRandomData = () => {
        const data = [];
        const startDate = new Date();
        for (let i = 0; i < 30; i++) {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() - i);

            const value = Math.floor(Math.random() * 100) + 1;

            data.push({
                x: date.toISOString().split('T')[0],
                y: value,
            });
        }
        return data;
    };


    render() {
        console.log(this.state.data2)
        const filteredData = this.state.data3.filter(item => item.text !== "0");
        return (
            <>
                <h1>Admin Dashboard</h1>

                {/* Add a chart for number of estimates generated over the last 30 days. */} <br />
                <>{ this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : <>
                <div className="row card flex-row mb-4">

                    <div className="col-md-8">
                        <ChartComponent id='charts' width='100%' primaryXAxis={this.state.primaryXAxis} primaryYAxis={this.state.primaryYAxis} tooltip={{ enable: true }} legendSettings={{ visible: true }} title='Estimates Generated Over The Last 30 Days'>
                            <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category, DateTime]} />
                            <SeriesCollectionDirective>
                                    <SeriesDirective dataSource={this.state.data2} xName='x' yName='y' name='Estimates' type='Column'>
                                </SeriesDirective>
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    </div>
                    {/* Add a sales funnel chart for the value of estimates in each status. */}
                    <div className="col-md-4">
                        <AccumulationChartComponent id='pie-chart2' style={{ textAlign: "center" }} ref={pie => this.pie = pie} legendSettings={{
                            visible: true, toggleVisibility: false,
                            position: 'Right', height: '28%', width: '44%',
                            textWrap: 'Wrap',
                            maximumLabelWidth: 100,
                        }} enableSmartLabels={true} selectionMode={'Point'} enableBorderOnMouseMove={false} tooltip={{ enable: false, header: '<b>${point.x}</b>', format: 'Composition: <b>${point.y}%</b>' }}>
                            <Inject services={[PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation, AccumulationAnnotation]} />
                            <AccumulationSeriesCollectionDirective>
                                    <AccumulationSeriesDirective name='Estimates' dataSource={filteredData} xName='x' yName='y' startAngle={0} endAngle={360} dataLabel={{
                                    visible: true, position: 'Inside',
                                    name: 'text',
                                    font: { color: 'white', fontWeight: '600', size: '14px' }
                                }} pointColorMapping='color'>
                                </AccumulationSeriesDirective>
                            </AccumulationSeriesCollectionDirective>
                            </AccumulationChartComponent>

                                <div className="my-3">
                                <div className="row">
                                    {this.state.data3.map((item, index) => (
                                        <ColorLegend key={index} title={item.x} color={item.color} size="col" />
                                        ))}
                                        </div>
                                </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/quotes" className="btn text-white bg-gradient w-100 position-relative" style={{ backgroundColor: "#00b3ad" }}><i className="far fa-file-lines me-2"></i>
                            RFQ
                            {/*<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-danger">*/}
                            {/*  9+*/}
                            {/*  <span class="visually-hidden">new quotes</span>*/}
                            {/*</span>*/}
                            <span className="badge rounded-pill badge-danger position-absolute top-0 start-100 translate-middle badge ">{!this.state.loading && this.state.editData.length}</span>
                        </Link>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/users" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="fas fa-users me-2"></i>Users</Link>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/companies" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="far fa-building me-2"></i>Companies</Link>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/categories" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="fas fa-folder-tree me-2"></i>Categories</Link>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/event-types" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="fas fa-champagne-glasses me-2"></i>Event Types</Link>
                    </div>
                    <div className="col-md-3 mb-3">
                        <Link to="/admin/prices" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="far fa-money-bill-1 me-2"></i>Prices</Link>
                    </div>
                    <div className="col-md-3 mb-3">
                            <Link to="/admin/cities" className="btn text-white bg-gradient w-100" style={{ backgroundColor: "#00b3ad" }}><i className="fa fa-city me-2"></i>Cities</Link>
                    </div>
                </div>
                </>}
                </>





            </>

        );
    }

    async loadData() {
        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/estimates/GetPieChartData', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ data3: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/estimates/GetGraphData', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                this.setState({ data2: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/estimates/GetAllPendingEstimates', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

    }


}


import React, { Component } from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Button } from 'reactstrap';
import { fieldRequired } from '../helpers/validation';
import { setAuthToken, setUserDetails } from '../helpers/authentication';
import { Link } from 'react-router-dom';
import { setIsAdmin } from '../helpers/Lookup';



export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            username: '',
            message: '',
        };
    }

    componentDidMount() {
        document.title = "Derwalt Quoting System";
        //this.loadData();
    }

    verifyEmail = async (event) => {
        event.stopPropagation();
        //var valid = true;
        let isValid = true;
        isValid &= fieldRequired(this.state.username, "errEmail", "required");

        if (isValid) {
            //  const data = this.state.editData.find((item) => { return item.Id == this.state.editId });
            //  if (this.state.editId > 0) { //do not overwrite the following fie when updating
            //    this.state.AddedBy = data.AddedBy;
            //    this.state.DateAdded = data.DateAdded;
            //    this.state.Id = data.Id;
            //    this.state.Status = data.Status;
            //  }
            //  else {
            //    this.state.Status = 1;
            //  }
            this.Login(this.state.username);
            //  this.setState({ editModal: false });
        }
    }



    render() {
        return (
            <div className="login-background">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                            <div className="card my-5">
                                <div className="card-body">
                                    <div className="text-center">
                                        <img src="/img/derwalt-logo.png" alt="Derwalt" height="120px" />
                                    </div>
                                    <h5 className="card-title text-center mt-5">Quote System Forgot Password</h5>

                                    <div className='mb-3'>
                                        <TextBoxComponent id='tbEmail' name='tbEmail' placeholder='Email' type='text' required='true' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.username} onChange={e => this.setState({ username: e.target.value })} /> <div id='tbUsernameError' className='error-message' />
                                        <span id="errEmail" className="required-icon" />
                                    </div>

                                    <div className="text-end mb-3">
                                        <Button className="brand-btn" onClick={this.verifyEmail}>Reset Password <i className="fa-solid fa-circle-chevron-right ms-2"></i></Button>
                                        {/*<input type="button" className="btn btn-secondary bg-gradient" value='Login <i className="fa-solid fa-circle-chevron-right ms-2"></i>' ></input>*/}
                                        {/*<a href="#" role="button" className="btn btn-secondary bg-gradient" onClick={this.attemptLogin}>Login <i className="fa-solid fa-circle-chevron-right ms-2"></i></a>*/}
                                    </div>
                                    <p>{this.state.message}</p>
                                    <div className="d-flex justify-content-between mt-4">
                                        <small className="align-self-end login">
                                            Back to <Link to="/login">Login</Link>
                                        </small>
                                    </div>
                                    <hr />
                                    <div className="text-center small">
                                        Version: 0.1
                                    </div>

                                    {/*<div className="form-label-group">*/}
                                    {/*  <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autofocus />*/}
                                    {/*  <label for="inputEmail">Email address</label>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/*<h1>Login!</h1>*/}
                {/*<p>Welcome to your new single-page application, built with:</p>*/}
                {/*<ul>*/}
                {/*  <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>*/}
                {/*  <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>*/}
                {/*  <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>*/}
                {/*</ul>*/}
                {/*<p>To help you get started, we have also set up:</p>*/}
                {/*<ul>*/}
                {/*  <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>*/}
                {/*  <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>*/}
                {/*  <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>*/}
                {/*</ul>*/}
                {/*<p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>*/}
            </div>
        );
    }

    async Login(email) {

        this.setState({ loading: true });

        try {
            const response = await fetch('/api/login/forgotpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                this.setState({ message: 'An email has been sent with a link to reset your password.', loading: false });
            } else {
                this.setState({ message: 'Failed to reset password. Please check your email address.', loading: false });
            }
        } catch (e) {
            this.setState({ loading: false });
            console.error(e);
            ToastUtility.show({
                title: "Forgot Password",
                content: "Email is invalid",
                cssClass: "e-toast-danger",
                position: { X: "Right", Y: "Top" },
                timeOut: 5000,
                showCloseButton: true,
            });
        }
    }
}
import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { getAuthToken, getUserDetails } from '../helpers/authentication';
import { Link } from 'react-router-dom';
import { Context } from '../helpers/Context';
import { confirm } from "react-confirm-box";

export class CustomerDashboard extends Component {
  //static displayName = ForgotPassword.name;
  static contextType = Context;

  constructor(props) {
      super(props);

      const rejectionReasons = [
          { value: 0, text: '- Select -' },
          { value: 1, text: 'Unavailable Date' },
          { value: 2, text: 'Last-Minute Request' },
          { value: 3, text: 'Unspecified Details' },
          { value: 4, text: 'Incomplete Information' },
          { value: 5, text: 'Mismatched Services' },
          { value: 6, text: 'Previous Issues' },
          { value: 10, text: 'Other' },
      ];

    this.state = {
      editData: [], orderData: [], loading: true, editModal: false,
      Id: 0,
      CompanyId: 0,
      UserId: 0,
      EventTypeId: 0,
      CityId: 0,
      EstNumber: 0,
      ProjectTitle: '',
      ProjectPax: 0,
      ProjectNumber: '',
      ProjectDescription: '',
      ProjectLocation: '',
      ProjectVenue: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      RejectionReason: 0,
      TotalAmount: 0,
      GrandTotal: 0,
      VatAmount: 0,
      RejectionReasonOther: '',
      EventTypeList: [],
      CategoryList: [],
      CityList: [],
      RejectionReasonsList: rejectionReasons,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Derwalt Quoting System";
    this.loadData();
  }

  calculatePrice = (items, cityId, pax) => {
    let totalPrice = items.reduce((accumulator, item) => accumulator + item.Price, 0);

    if (cityId) {
      const value = this.state.CityList.find(item => item.id === cityId)
      totalPrice = totalPrice * value.multiplier;
      //console.log(items, cityId, totalPrice, value)
    }
    return totalPrice;
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      this.setState({ Id: data.id, Title: data.title, DisplayOrder: data.displayOrder, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        ProjectTitle: '',
        CompanyId: 0,
        EventTypeId: 0,
        UserId: 0,
        ProjectPax: '',
        ProjectNumber: '',
        ProjectDescription: '',
        ProjectLocation: '',
        ProjectVenue: '',
        RejectionReason: '',
        RejectionReasonOther: '',
        ProjectDate: new Date(),
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  handleEstimateDownload = (event, data, pax) => {
    event.stopPropagation();

      const elements = this.getElements(data.estimateItems, data.projectPax);
    console.log(elements, data);
    const estimate = {
      EstNumber: data.estNumber,
      ProjectTitle: data.projectTitle,
      CompanyName: data.companyName,
      UserName: data.userName,
      DateAdded: data.dateAdded,
      ProjectDate: data.projectDate,
      EventName: data.eventName,
      ProjectPax: data.projectPax,
      ProjectLocation: data.projectLocation,
      Elements: JSON.stringify(elements),
      Total: this.calculatePrice(data.estimateItems, data.cityId, data.projectPax),
    };
    //console.log(elements);
    this.downloadEstimate(estimate);
  };

  //getElements = (estimates) => {
  //    let result = [];
  //    const estimatesCategoryIds = new Set(estimates.map(item => item.CategoryId));
  //    const groupedCategories = this.state.CategoryList
  //        .filter(category => estimatesCategoryIds.has(category.id))
  //        .reduce((acc, category) => {
  //            const parentId = category.parentId > 0 ? category.parentId : category.id;
  //            acc[parentId] = [...(acc[parentId] || []), category];
  //            return acc;
  //        }, {});

  //    result = Object.entries(groupedCategories).map(([parentId, categoryItems]) => {
  //        const categoryName = this.state.CategoryList.find(category => category.id === parseInt(parentId)).name;
  //        const totalPrice = categoryItems.reduce((sum, categoryItem) => {
  //            const categoryEstimates = estimates.filter(estimate => estimate.CategoryId === categoryItem.id);
  //            const categoryPrice = categoryEstimates.reduce((itemSum, estimate) => itemSum + estimate.Price, 0);
  //            return sum + categoryPrice;
  //        }, 0);

  //        return { Category: categoryName, Price: totalPrice };
  //    });

  //    return result;
  //}

  getElements = (estimates, pax) => {
    const estimatesCategoryIds = new Set(estimates.map(item => item.CategoryId));
    console.log(estimates)
    const result = Object.entries(
      this.state.CategoryList
        .filter(category => estimatesCategoryIds.has(category.id))
        .reduce((acc, category) => {
          const parentId = category.parentId > 0 ? category.parentId : category.id;
          acc[parentId] = [...(acc[parentId] || []), category];
          return acc;
        }, {})
    ).map(([parentId, categoryItems]) => {
      const categoryName = this.state.CategoryList.find(category => category.id === parseInt(parentId)).name;
      const totalPrice = categoryItems.reduce((sum, categoryItem) => {
        const categoryPrice = estimates
          .filter(estimate => estimate.CategoryId === categoryItem.id)
            .reduce((itemSum, estimate) => itemSum + estimate.Price, 0);
        return sum + categoryPrice;
      }, 0);

      return { Category: categoryName, Price: totalPrice };
    });

    return result;
  }

  saveItem = async (event, id) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == id });
      //if (this.state.editId > 0) { //do not overwrite the following fie when updating
      //  this.state.AddedBy = data.addedBy;
      //  this.state.DateAdded = data.dateAdded;
      //  this.state.Id = data.id;
      //  this.state.Status = 2;
      //}
      //else {
      //  this.state.Status = 2;
      //}
      this.requestQuote(data);
      //this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this Estimate?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  render() {
    let data = this.state.editData;
    const { handleNavigate } = this.context;
    console.log(this.state)
    return (
      <>
        <div className="estimates-background">
          <div className="container" style={{ position: "relative" }}>
          <Row>
            <Col xs={4}>
              <h1>Dashboard</h1>
            </Col>
            <Col xs={8} className="text-end align-self-center">
              <Link to="https://www.derwalt.com/book-online" target="_blank" className="btn brand-btn btn-sm me-2">Schedule a Meeting<i className="far fa-calendar ms-2"></i></Link>
              {/*<Button hre className="brand-btn me-2" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Schedule a Meeting<i className="far fa-calendar ms-2"></i></Button>*/}
              {/*<Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Generate an Estimate<i className="fas fa-plus-circle ms-2"></i></Button>*/}
              <Link to={getAuthToken() ? "/estimates/0" : "/login"} className="btn brand-btn btn-sm">Generate an Estimate<i className="fas fa-plus-circle ms-2"></i></Link>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12}>
              <div className="row customer-card">
                {!this.state.loading && data && data.length > 0 ? (
                  data.map((item, index) => (
                    <div key={index} className="col-lg-4 col-md-6 mb-3">
                      <div className="card h-100">
                        <div className="card-header">{item.projectTitle}</div>
                        <div className="card-body">
                          <label>Project Number:</label> {item.projectNumber}<br />
                          <label>Event Type:</label> {item.eventName}<br />
                          <label>Date:</label> {new Date(item.projectDate).toDateString()}<br />
                          <label>Date:</label> {new Date(item.projectDate).toDateString()}<br />
                          <label>Venue:</label> {item.projectVenue}<br />
                          <label>Status:</label> {item.status === 1 ? <strong>Estimate</strong> : item.status === 2 ? <strong className="text-warning">Pending</strong> : item.status === 4 ? <strong className="text-danger">Rejected</strong> : <strong className="text-success">Quote Generated</strong>}<br />
                                  {item.status === 4 ? <><label>Reason:</label> {this.state.RejectionReasonsList.find(reason => reason.value === item.rejectionReason).text}<br /></> : "" }
                                  <label>Total Amount:</label> {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculatePrice(item.estimateItems, item.cityId, item.projectPax))}<br />
                        </div>
                        {item.status === 1 ?
                          <div className="card-footer">
                            <Button onClick={(e) => getAuthToken() ? this.saveItem(e, item.id) : handleNavigate("/login")} className="btn btn-sm brand-btn me-2 mb-2">Request Quote</Button>
                            <Button onClick={(e) => getAuthToken() ? this.handleEstimateDownload(e, item) : handleNavigate("/login")} className="btn btn-sm brand-btn me-2 mb-2">View Estimate</Button>
                            <br />
                            <Button onClick={(e) => getAuthToken() ? handleNavigate("/estimates/" + item.id) : handleNavigate("/login")} className="btn btn-sm btn-warning me-2 mb-2">Edit Estimate</Button>
                            <Button onClick={(e) => getAuthToken() ? this.deleteItem(item.id) : handleNavigate("/login")} className="btn btn-sm btn-danger me-2 mb-2">Delete Estimate</Button>
                          </div> : item.status === 2 ?
                            <div className="card-footer">
                              <Button onClick={(e) => getAuthToken() ? this.handleEstimateDownload(e, item) : handleNavigate("/login")} className="btn btn-sm brand-btn me-2 mb-2">View Estimate</Button>
                              <br />
                              {/*<Button onClick={(e) => getAuthToken() ? handleNavigate("/estimates/" + item.id) : handleNavigate("/login")} className="btn btn-sm btn-warning me-2 mb-2">Edit Estimate</Button>*/}
                              <Button onClick={(e) => getAuthToken() ? this.deleteItem(item.id) : handleNavigate("/login")} className="btn btn-sm btn-danger me-2 mb-2">Delete Estimate</Button>
                            </div> : item.status === 4 ?
                              <div className="card-footer">
                                {/*<Button className="btn btn-sm brand-btn me-2 mb-2" onClick={(e) => this.downloadQuote(e, item.id)}>Download Quote</Button>*/}
                                <Button onClick={(e) => getAuthToken() ? this.deleteItem(item.id) : handleNavigate("/login")} className="btn btn-sm btn-danger me-2 mb-2">Delete Estimate</Button>
                              </div> :
                              <div className="card-footer">
                                <Button className="btn btn-sm brand-btn me-2 mb-2" onClick={(e) => this.downloadQuote(e, item.id)}>Download Quote</Button>
                                <Button onClick={(e) => getAuthToken() ? this.deleteItem(item.id) : handleNavigate("/login")} className="btn btn-sm btn-danger me-2 mb-2">Delete Estimate</Button>
                              </div>}
                      </div>
                    </div>
                  ))) : !this.state.loading && data && data.length == 0 ? (<p className='text-center'>No Estimates Found</p>) : (
                    <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p>
                  )}
              </div>

            </Col>
          </Row>
        </div>
        </div>
      </>
    );
  }
  async loadData() {
    const user = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('api/cities', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ CityList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/categories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ CategoryList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/estimates/getUserEstimates/' + user?.id, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, estimateItems: JSON.parse(item.estimateItems) }))
        //console.log(data)
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        this.setState({ loading: false });
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }

  }

  async downloadEstimate(data) {
    this.setState({ loading: true, showError: false, showSuccess: false });

    try {
      const bearer = "Bearer " + localStorage.getItem("token");
      const response = await fetch('api/estimates/GeneratePdfDocument', {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.item1;
        const MimeType = data.item2;
        const Filename = data.item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        //const LinkBtn = document.createElement("a");
        //LinkBtn.download = Filename;
        //LinkBtn.href = Base64String;
        //LinkBtn.click();
        //const newWindow = window.open("data:application/octet-stream;charset=utf-16le;base64," + escape(FileData));
        //newWindow.document.write(`<iframe width='100%' height='100%' src='${Base64String}'></iframe>`);#toolbar=0&navpanes=0&scrollbar=0
        let pdfWindow = window.open("");
        pdfWindow.document.write("<html<head><title>" + Filename + "</title><style>body{margin: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(FileData) + "'></embed></body></html>");
        pdfWindow.document.close();

        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Estimates', content: 'The estimate was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      } else {
        if (response.status === 401) {
          window.location.href = "/";
        } else
          this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error)
      ToastUtility.show({
        title: 'Estimates', content: 'There was an error downloading the estimate!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async requestQuote(cardData) {
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: cardData.id, CompanyId: cardData.companyId, UserId: cardData.userId, EventTypeId: cardData.eventTypeId, CityId: cardData.cityId, EstNumber: cardData.estNumber, ProjectTitle: cardData.projectTitle, ProjectPax: cardData.projectPax, ProjectNumber: cardData.projectNumber, ProjectDescription: cardData.projectDescription, ProjectLocation: cardData.projectLocation, ProjectVenue: cardData.projectVenue, ProjectDate: cardData.projectDate, DateAdded: cardData.dateAdded, AddedBy: cardData.addedBy, DateModified: cardData.dateModified, ModifiedBy: cardData.modifiedBy, Status: 2, RejectionReason: cardData.rejectionReason, RejectionReasonOther: cardData.rejectionReasonOther, }

    try {
      const response = await fetch('api/estimates', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Estimates', content: 'The request was successfully sent!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Estimates', content: 'There was an error sending the request!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/estimates/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Estimates', content: 'The estimate was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Estimates', content: 'There was an error deleting the estimates!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async downloadQuote(e, id) {
    e.stopPropagation();

    const bearer = "Bearer " + localStorage.getItem("token");
    try {
      const response = await fetch("api/quotes/downloadquote/" + id, {
        method: "GET",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/pdf"
        }
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.item1;
        const Filename = data.item2;
        const MimeType = data.item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        // Create a URL from the response blob
        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();

        ToastUtility.show({
          title: 'Quotes', content: 'The quote was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      } else {
        if (response.status === 401) {
          window.location.href = "/";
        } else {
          ToastUtility.show({
              title: 'Quotes', content: 'There was an error downloading the quote!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }
    } catch (e) {
      ToastUtility.show({
          title: 'Quotes', content: 'There was an error downloading the quote!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
      console.log(e);
    }
  }

}
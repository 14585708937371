import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { confirm } from "react-confirm-box";
import './NavMenu.css';
import { clearUserDetails, getAuthToken, getUserDetails } from '../helpers/authentication';
import { Context } from '../helpers/Context';

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = Context;
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleLogout() {
        const { handleNavigate } = this.context;
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to log out?", confirmStyles);
        if (result) {
            clearUserDetails();
            handleNavigate("/login");
        }
    }
    render() {
    const isAdmin = getUserDetails()?.userType == 2 || getUserDetails()?.userType == 4 ? true : false;
        return (
            <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to={isAdmin ? "/admin" : "/"}><img src="/img/derwalt-logo.png" alt="Derwalt Quoting System" style={{ height: "60px" }} /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {/*<NavItem>*/}
                            {/*  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                            {/*  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                            {/*</NavItem>*/}
                            {isAdmin && <NavItem>
                                <NavLink tag={Link} className="text-dark fw-bold" to="/"><span className="nav-hover">Estimates</span></NavLink>
                            </NavItem>}
                            {getAuthToken() ? <NavItem>
                                <NavLink tag={Link} onClick={() => this.handleLogout()} className="text-dark fw-bold" ><span className="nav-hover">Logout <i className="fa-solid fa-arrow-right-from-bracket ms-2"></i></span></NavLink>
                            </NavItem> :
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark fw-bold" to="/login" ><span className="nav-hover">Login <i className="fa-solid fa-arrow-right-to-bracket ms-2"></i></span></NavLink>
                                </NavItem>}
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, setAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Link } from 'react-router-dom';

export class EventTypes extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
        editData: [], orderData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Title: '',
      DisplayOrder: 0,
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,

    };
    this.toggle = this.toggle.bind(this);
    this.toggleOrderModal = this.toggleOrderModal.bind(this);
    this.onItemDrop = this.onItemDrop.bind(this);
    this.SaveOrder = this.SaveOrder.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleOrderModal() {
    this.setState({
      orderModal: !this.state.orderModal
    });
  }

  componentDidMount() {
    document.title = "Event Types Administration";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      this.setState({ Id: data.id, Title: data.title, DisplayOrder: data.displayOrder, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Title: '',
        DisplayOrder: 0,
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
      valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required', 'tbTitle');
    //valid &= fieldRequired(this.state.DisplayOrder, 'tbDisplayOrderError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.Id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
    }

    capitalizeKeys = (obj) => {
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            newObj[capitalizedKey] = obj[key];
        }
    }
    return newObj;
}

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
    }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
        <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} enableInfiniteScrolling={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          {/*<ColumnDirective field='DisplayOrder' width='50' headerText="Display Order" />*/}
          <ColumnDirective field='Title' width='300' headerText="Title" />
          <ColumnDirective headerText='Actions' width='70' commands={gridCommands} />
        </ColumnsDirective>
            <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
      </GridComponent>
    );
  }

  onItemDrop(e) {
    const orderData = [];
    e.source.currentData.map((item, idx) =>
        orderData.push({ ...item, DisplayOrder: (idx + 1)})
    );
      this.setState({ orderData })
      //console.log(orderData, e.source.currentData)
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : EventTypes.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Event Types Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/admin" className="btn btn-outline-dark btn-sm me-2"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
              <Button className="me-2 btn-dark" size="sm" data-recordid="0" onClick={this.toggleOrderModal}>Order Data <i className="fa-solid fa-sort ms-2"></i></Button>
              <Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
                        {contents}
                        <br />
                        <br />
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Event Types</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
            </div>

            {/*<div className='mb-3'>*/}
            {/*  <NumericTextBoxComponent id='tbDisplayOrder' name='tbDisplayOrder' placeholder='Display Order' floatLabelType='Auto' showClearButton={true} value={this.state.DisplayOrder} onChange={e => this.setState({ DisplayOrder: e.target.value })} /> <div id='tbDisplayOrderError' className='error-message' />*/}
            {/*</div>*/}


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
          <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

          <ModalBody>
            <ListBoxComponent dataSource={this.state.orderData} allowDragAndDrop={true} fields={{ text: "title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={this.SaveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>


      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/eventtypes', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
          this.setState({ editData: data, orderData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
      setAuthToken(getAuthToken(), new Date());
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Title: this.state.Title, DisplayOrder: this.state.DisplayOrder, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/eventtypes', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Event Types', content: 'The event types was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Event Types', content: 'There was an error saving the event types!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async SaveOrder() {
      var bearer = 'Bearer ' + getAuthToken();
      const capitalizedOrderData = this.state.orderData.map(item => this.capitalizeKeys(item));
      const data = JSON.stringify(capitalizedOrderData);
    this.setState({ orderModal: false, loading: true })
    try {
      const response = await fetch("api/eventtypes/SaveDisplayOrder", {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: data
      });

      if (response.ok) {
        //toastNotification("Display Order", 'Display order was successfully saved!', true);
        ToastUtility.show({
          title: 'Event Types', content: 'The display order was successfully updated!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
        this.loadData();
      }

    } catch (e) {
      console.error(e);
    } finally {
        this.setState({ loading: false });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/eventtypes/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Event Types', content: 'The event types was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Event Types', content: 'There was an error deleting the event types!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}


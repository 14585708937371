import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../helpers/authentication';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const isLoggedIn = getAuthToken();
  //const isAdmin = getUserDetails()?.isAdmin;

  //const isLoggedIn = true;
    const isAdmin = getUserDetails()?.userType == 2 || getUserDetails()?.userType == 4 ? true : false;

  return (
    <Routes>
      <Route
        {...rest}
        //element={isLoggedIn && isAdmin > 1 ? Element : <Navigate to="/login" replace />}
        element={isLoggedIn && isAdmin  ? Element : <Navigate to="/login" replace />}
      />
    </Routes>
  );
};

export default ProtectedRoute;
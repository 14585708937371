import React, { Component } from 'react'
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

export default class ColorLegend extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    render() {
        return (
            <div className={this.props.size}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="flex-shrink-0">
                        <div className="colorBox " style={{ backgroundColor: this.props.color} }></div>
                    </div>
                    <div className="flex-grow-1 text-dark">
                        <small>{this.props.title}</small>
                    </div>
                </div>

            </div>
        )
    }
}

import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent  } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Link } from 'react-router-dom';

export class Cities extends Component {
  
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];
    
    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0, 
Name: '', 
Abbreviation: '', 
Multiplier: 0, 
DateAdded: new Date(), 
AddedBy: 0, 
DateModified: new Date(), 
ModifiedBy: 0, 
Status: 0, 

    };
    this.toggle = this.toggle.bind(this);
    
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Cities Administration";
    this.loadData();
  }

 editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      this.setState({ Id: data.id, Name: data.name, Abbreviation: data.abbreviation, Multiplier: data.multiplier, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status,  });
    }
    else {
      //clear state fields
      this.setState({ Id: 0, 
Name: '', 
Abbreviation: '', 
Multiplier: 0, 
DateAdded: new Date(), 
AddedBy: 0, 
DateModified: new Date(), 
ModifiedBy: 0, 
Status: 0, 
 });
    }
    this.setState({ editId: id, editModal: true});
  };
  
  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Name, 'tbNameError', '* required');
valid &= fieldRequired(this.state.Abbreviation, 'tbAbbreviationError', '* required');
valid &= fieldRequired(this.state.Multiplier, 'tbMultiplierError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async(id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result)
    {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
        <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} enableInfiniteScrolling={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Name' width='100' headerText="Name" />
                <ColumnDirective field='Abbreviation' width='60' headerText="Abbrv" />
                <ColumnDirective field='Multiplier' width='60' headerText="Multiplier" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
            <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Cities.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Cities Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
                        <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
                        {/*<Button className="mx-1" color="success" size="sm" data-recordid="0" onClick={e => this.uploadToggle()}> Upload Prices</Button>*/}
                        <Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="md" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Cities</ModalHeader>
          <ModalBody>
                    <div className='mb-3'>
        <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='150' floatLabelType='Auto' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
        </div>

        <div className='mb-3'>
        <TextBoxComponent id='tbAbbreviation' name='tbAbbreviation' placeholder='Abbreviation' type='text' maxLength='50' floatLabelType='Auto' showClearButton={true} value={this.state.Abbreviation} onChange={e => this.setState({ Abbreviation: e.target.value })} /> <div id='tbAbbreviationError' className='error-message' />
        </div>

        <div className='mb-3'>
        <NumericTextBoxComponent  id='tbMultiplier' name='tbMultiplier' placeholder='Multiplier' floatLabelType='Auto' showClearButton={true} value={this.state.Multiplier} onChange={e => this.setState({ Multiplier: e.target.value })} /> <div id='tbMultiplierError' className='error-message' />
        </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
     </>

    );
  }

  async loadData() {
    
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cities', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
          const data = await response.json();
          console.log(data)
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }
  
  async saveData(dataId) {
    
    this.setState({ loading: true, showError: false, showSuccess: false });
    
    var bearer = 'Bearer ' + getAuthToken();
    var data = {Id: this.state.Id, Id: this.state.Id, Name: this.state.Name, Abbreviation: this.state.Abbreviation, Multiplier: this.state.Multiplier, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }
    
    try {
      const response = await fetch('api/cities', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Cities', content: 'The cities was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Cities', content: 'There was an error saving the cities!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
  
  async deleteData(dataId) {
    this.setState({ loading: true });
    
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cities/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Cities', content: 'The cities was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Cities', content: 'There was an error deleting the cities!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}


import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { registerLicense } from '@syncfusion/ej2-base';
import AppContext from './helpers/Context';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

registerLicense('Mgo+DSMBaFt9QHFqVkFrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlpiSH9TckFjWH1deXE=;ORg4AjUWIQA/Gnt2V1hhQlJDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud0RiWXtacHVXQ2ZZ;Mgo+DSMBMAY9C3t2V1hhQlJDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud0RiWXtacHVXQWFZ');

root.render(
    <BrowserRouter basename={baseUrl}>
        <AppContext>
            <App />
        </AppContext>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setAuthToken, setUserDetails } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { fieldRequired } from '../helpers/validation';



export class Login extends Component {
  static displayName = Login.name;
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: '',
      password: '',
      Checked: false,
    };
  }

  componentDidMount() {
    document.title = "Derwalt Quoting System";
  }

  attemptLogin = async (event) => {
    event.stopPropagation();
    //var valid = true;
    let isValid = true;
      isValid &= fieldRequired(this.state.username, "tbUsernameError", "* required", 'tbUsername');
      isValid &= fieldRequired(this.state.password, "tbPasswordError", "* required", 'tbPassword');

    if (isValid) {
      this.Login();
    }
  }

  render() {
    return (
      <div className="login-background">
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card my-5">
                <div className="card-body">
                  <div className="text-center">
                    <img src="/img/derwalt-logo.png" alt="Derwalt" height="120px" />
                  </div>
                  <h5 className="card-title text-center mt-5">Quote System Sign In</h5>

                  <div className='mb-1'>
                    <TextBoxComponent id='tbUsername' name='tbUsername' placeholder='Username' type='text' required='true' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.username} onChange={e => this.setState({ username: e.target.value })} /> <div id='tbUsernameError' className='error-message' />

                  </div>
                  <div className='mb-3'>
                    <TextBoxComponent id='tbPassword' name='tbPassword' placeholder='Password' type='password' required='true' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.password} onChange={e => this.setState({ password: e.target.value })} /> <div id='tbPasswordError' className='error-message' />

                  </div>

                  <div className="text-end">
                    <a role="button" className="btn text-white bg-gradient " style={{ backgroundColor: "#00b3ad" }} onClick={this.attemptLogin}>Login <i className="fa-solid fa-circle-chevron-right ms-2"></i></a>
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <small className="align-self-start login">
                      <Link to="/forgot-password">Forgot password? </Link>
                    </small>
                  </div>

                  <hr />
                  <div className="text-center small">
                    Version: 0.2
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  async Login() {
    const { handleNavigate } = this.context;
    this.setState({ loading: true });

    try {

      const response = await fetch(`api/login/UserLogin?username=${this.state.username}&password=${this.state.password}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({ loading: false });
        setAuthToken(data.item1, new Date());
        setUserDetails(data.item3);
        ToastUtility.show({
          title: "Login",
          content: "Login is successful",
          cssClass: "e-toast-success",
          position: { X: "Right", Y: "Bottom" },
          timeOut: 5000,
          showCloseButton: true,
        });

        switch (data.item3.userType) {
          case 4:
            handleNavigate("/admin");
            break;
          default:
            handleNavigate("/dashboard");
            break;
        }

        //if (data.item2) {
        //  //handleNavigate("/admin");
        //} else {
        //  ToastUtility.show({
        //    title: "Login",
        //    content: "Only an admin can browse the website",
        //    cssClass: "e-toast-info",
        //    position: { X: "Right", Y: "Top" },
        //    timeOut: 5000,
        //    showCloseButton: true,
        //  });

        //}
      }
      else {
        this.setState({ loading: false });
        if (response.status === 400)
          ToastUtility.show({
            title: "Login",
            content: "Your account has been locked out",
            cssClass: "e-toast-info",
            position: { X: "Right", Y: "Top" },
            timeOut: 5000,
            showCloseButton: true,
          });
        else if (response.status === 504)
          ToastUtility.show({
            title: "Login",
              content: "There was an error logging you in, please try again after a few minutes",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Bottom" },
            timeOut: 5000,
            showCloseButton: true,
          });
        else
          ToastUtility.show({
            title: "Login",
            content: "Email/Password is invalid",
            cssClass: "e-toast-danger",
            position: { X: "Right", Y: "Bottom" },
            timeOut: 5000,
            showCloseButton: true,
          });
      }
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
      ToastUtility.show({
        title: "Login",
        content: "Email/Password is invalid",
        cssClass: "e-toast-danger",
        position: { X: "Right", Y: "Bottom" },
        timeOut: 5000,
        showCloseButton: true,
      });
    }
  }
}

import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col, FormGroup, FormText } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, InfiniteScroll, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, setAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Link } from 'react-router-dom';

export class UserData extends Component {

    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        const userTypeData = [
            { value: 0, text: '- Select -' },
            { value: 1, text: 'Company User' },
            { value: 2, text: 'Company Admin' },
            { value: 3, text: 'DerWalt User' },
            { value: 4, text: 'Site Admin' },
        ];

        this.state = {
            editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            fCompanyId: 0,
            UserType: 0,
            UserId: '',
            Firstname: '',
            Lastname: '',
            EmailAddress: '',
            CurrentPassword: '',
            LastPassword: '',
            Mobile: '',
            Telephone: '',
            Newsletter: false,
            IsAdmin: false,
            Permissions: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            DateVerified: new Date(),
            LastLogin: new Date(),
            FailedLogins: 0,
            LockedOut: false,
            LockOutDate: new Date(),
            ResetPassword: false,
            LastIP: '',
            LoginCookie: '',
            ConfirmPassword: '',
            CompanyList: [],
            userTypes: userTypeData,
        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "User Administration :: Derwalt";
        this.loadData();
    }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({
                Id: data.id, CompanyId: data.companyId, UserId: data.userId, UserType: data.userType, Firstname: data.firstname, Lastname: data.lastname, EmailAddress: data.emailAddress, CurrentPassword: '', ConfirmPassword: '', LastPassword: data.lastPassword, Mobile: data.mobile, Telephone: data.telephone, Newsletter: data.newsletter, IsAdmin: data.isAdmin, Permissions: data.permissions, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, DateVerified: data.dateVerified, LastLogin: data.lastLogin, FailedLogins: data.failedLogins, LockedOut: data.lockedOut, LockOutDate: data.lockOutDate, ResetPassword: data.resetPassword, LastIP: data.lastIP, LoginCookie: data.loginCookie, });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                CompanyId: 0,
                UserType: 0,
                UserId: '',
                Firstname: '',
                Lastname: '',
                EmailAddress: '',
                CurrentPassword: '',
                LastPassword: '',
                Mobile: '',
                Telephone: '',
                Newsletter: false,
                IsAdmin: false,
                Permissions: '',
                DateAdded: new Date(),
                AddedBy: 0,
                DateModified: new Date(),
                ModifiedBy: 0,
                Status: 0,
                DateVerified: new Date(),
                LastLogin: new Date(),
                FailedLogins: 0,
                LockedOut: false,
                LockOutDate: new Date(),
                ResetPassword: false,
                ConfirmPassword: '',
                LastIP: '',
                LoginCookie: '',

            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;
        //valid &= fieldRequired(this.state.CompanyId, 'tbCompanyIdError', '* required');
        //valid &= fieldRequired(this.state.UserId, 'tbUserIdError', '* required');
        valid &= fieldRequired(this.state.Firstname, 'tbFirstnameError', '* required', 'tbFirstname');
        valid &= fieldRequired(this.state.EmailAddress, 'tbEmailAddressError', '* required', 'tbEmailAddress');
        //valid &= fieldRequired(this.state.ConfirmPassword === this.state.CurrentPassword, 'tberrConfirmPassword', 'Password doesn\'t match', 'tbConfirmPassword')
        //valid &= fieldRequired(this.state.CurrentPassword, 'tbCurrentPasswordError', '* required');
        //valid &= fieldRequired(this.state.IsAdmin, 'tbIsAdminError', '* required');

        if (valid) {
            const data = this.state.editData.find((item) => { return item.id == this.state.editId });
            if (this.state.editId > 0) { //do not overwrite the following fie when updating
                //this.state.AddedBy = data.AddedBy;
                //this.state.DateAdded = data.DateAdded;
                //this.state.Id = data.Id;
                //this.state.Status = data.Status;
                this.setState({ AddedBy: data.addedBy, DateAdded: data.dateAdded, Id: data.id, Status: data.status });
            }
            else {
                //this.state.Status = 1;
                this.setState({ Status: 1 });
            }
            console.log(this.state.editId)
            this.saveData(this.state.editId);
            this.setState({ editModal: false });
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
        }
    }

    static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} enableInfiniteScrolling={true} toolbar={gridToolbar} >
                <ColumnsDirective>
                    <ColumnDirective field='Firstname' width='50' headerText="First Name" />
                    <ColumnDirective field='Lastname' width='50' headerText="Last Name" />
                    <ColumnDirective field='EmailAddress' width='100' headerText="Email Address" />
                    <ColumnDirective field='CompanyName' width='100' headerText="Company" />
                    <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
            </GridComponent>
        );
    }

    render() {
        let data = this.state.editData;
        if (this.state.fCompanyId !== 0) {
            data = data?.filter(item => item.companyId === this.state.fCompanyId || (!this.state.fCompanyId && !item.companyId));
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : UserData.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
        //console.log(this.state)
        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={4}>
                            <h1>User Administration</h1>
                        </Col>
                        <Col xs={8} className="text-end align-self-center">
                            <Link to="/admin" className="btn btn-outline-dark btn-sm me-2"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
                            <Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='card card-body shadow mb-3'>
                                <b>Filters</b>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <DropDownListComponent id='ddfCompanyId' name='ddfCompanyId' placeholder='Company' dataSource={[{ name: "All", id: 0 }, ...this.state.CompanyList]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.fCompanyId} change={e => this.setState({ fCompanyId: e.itemData ? e.itemData.id : 0 })} />
                                    </div>
                                </div>

                            </div>
                            {contents}
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit User Data</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className='col-md-6 mb-1'>
                            {/*<Label for='ddCompanyId'>Company</Label>*/}
                                <DropDownListComponent id='ddCompanyId' name='ddCompanyId' placeholder='Company' dataSource={[{ name: "- Select -", id: 0 }, ...this.state.CompanyList]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.CompanyId} change={e => { this.setState({ CompanyId: e.value }); console.log(e.value, e) }} />
                        </div>

                        <div className='col-md-6 mb-1'>
                            <DropDownListComponent id='ddUserType' name='ddUserType' placeholder='User Type' dataSource={this.state.userTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.UserType} change={e => this.setState({ UserType: e.itemData.value })} />
                        </div>
                        </div>

                        <div className="row">

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbFirstname' name='tbFirstname' placeholder='Firstname' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Firstname} onChange={e => this.setState({ Firstname: e.target.value })} /> <div id='tbFirstnameError' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbLastname' name='tbLastname' placeholder='Lastname' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Lastname} onChange={e => this.setState({ Lastname: e.target.value })} /> <div id='tbLastnameError' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-12'>
                                <TextBoxComponent id='tbEmailAddress' name='tbEmailAddress' placeholder='Email Address' type='email' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.EmailAddress} onChange={e => this.setState({ EmailAddress: e.target.value })} /> <div id='tbEmailAddressError' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbCurrentPassword' name='tbCurrentPassword' placeholder='Current Password' type='password' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.CurrentPassword} onChange={e => this.setState({ CurrentPassword: e.target.value })} /> <div id='tbCurrentPasswordError' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbConfirmPassword' name='tbConfirmPassword' placeholder='Confirm Password (should match the previous password field.)' type='password' value={this.state.ConfirmPassword} maxLength='350' floatLabelType='Always' showClearButton={true} onChange={e => this.setState({ ConfirmPassword: e.target.value })} onBlur={e => fieldRequired(this.state.CurrentPassword && e.target.value === this.state.CurrentPassword, 'tberrConfirmPassword', 'Password doesn\'t match')} /> <div id='tberrConfirmPassword' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbMobile' name='tbMobile' placeholder='Mobile' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Mobile} onChange={e => this.setState({ Mobile: e.target.value })} /> <div id='tbMobileError' className='error-message' />
                            </div>

                            <div className='mb-1 col-md-6'>
                                <TextBoxComponent id='tbTelephone' name='tbTelephone' placeholder='Telephone' type='text' maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Telephone} onChange={e => this.setState({ Telephone: e.target.value })} /> <div id='tbTelephoneError' className='error-message' />
                            </div>

                            {/*<div className='mb-1 col-md-4'>*/}
                            {/*    <CheckBoxComponent id='cbNewsletter' name='cbNewsletter' type='checkbox' label='Newsletter' checked={this.state.Newsletter} onChange={e => this.setState({ Newsletter: e.target.checked })} /> &nbsp; <div id='cbNewsletterError' className='error-message' />*/}
                            {/*</div>*/}

                            {/*<div className='mb-1 col-md-3'>*/}
                            {/*    <CheckBoxComponent id='cbIsAdmin' name='cbIsAdmin' type='checkbox' label='Is Admin' checked={this.state.IsAdmin} onChange={e => this.setState({ IsAdmin: e.target.checked })} /> &nbsp; <div id='cbIsAdminError' className='error-message' />*/}
                            {/*</div>*/}

                            <div className='mb-1 col-md-6'>
                                <CheckBoxComponent id='cbLockedOut' name='cbLockedOut' type='checkbox' label='Locked Out' checked={this.state.LockedOut} onChange={e => this.setState({ LockedOut: e.target.checked })} /> &nbsp; <div id='cbLockedOutError' className='error-message' />
                            </div>


                            <div className='mb-1 col-md-6'>
                                <CheckBoxComponent id='cbResetPassword' name='cbResetPassword' type='checkbox' label='Reset Password' checked={this.state.ResetPassword} onChange={e => this.setState({ ResetPassword: e.target.checked })} /> &nbsp; <div id='cbResetPasswordError' className='error-message' />
                            </div>
                        </div>



                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>
            </>

        );
    }

    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();

        try {
            const response = await fetch('api/companies', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CompanyList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }



        try {
            const response = await fetch('api/userdata', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }


        setAuthToken(getAuthToken(), new Date());

    }

    async saveData(dataId) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Id: this.state.Id, Id: this.state.Id, CompanyId: this.state.CompanyId, UserType: this.state.UserType, UserId: this.state.UserId, Firstname: this.state.Firstname, Lastname: this.state.Lastname, EmailAddress: this.state.EmailAddress, CurrentPassword: this.state.CurrentPassword, LastPassword: this.state.LastPassword, Mobile: this.state.Mobile, Telephone: this.state.Telephone, Newsletter: this.state.Newsletter, IsAdmin: this.state.IsAdmin, Permissions: this.state.Permissions, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, DateVerified: this.state.DateVerified, LastLogin: this.state.LastLogin, FailedLogins: this.state.FailedLogins, LockedOut: this.state.LockedOut, LockOutDate: this.state.LockOutDate, ResetPassword: this.state.ResetPassword, LastIP: this.state.LastIP, LoginCookie: this.state.LoginCookie, }

        try {
            const response = await fetch('api/userdata', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'User Data', content: 'The user data was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
                else if (response.status === 409) {
                    this.setState({ loading: false });
                    ToastUtility.show({
                        title: 'User Data', content: 'There is a user with that email already!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'User Data', content: 'There was an error saving the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/userdata/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'User Data', content: 'The user data was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'User Data', content: 'There was an error deleting the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}


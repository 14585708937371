import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { FormGroup, Label, Input, FormText, Button } from "reactstrap";

function ResetPassword() {
    const { userId } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fieldRequired = (value, errorState, errorMessage) => {
        if (!value) {
            setError(errorState, errorMessage);
        } else {
            setError(errorState, null);
        }
    };

    const setError = (errorState, errorMessage) => {
        switch (errorState) {
            case 'errPassword':
                setPasswordError(errorMessage);
                break;
            case 'errConfirmPassword':
                setConfirmPasswordError(errorMessage);
                break;
            default:
                break;
        }
    };

    const passwordFieldRequired = (isMatch, errorState, errorMessage) => {
        if (!isMatch) {
            setError(errorState, errorMessage);
        } else {
            setError(errorState, null);
        }
    };

    const handleResetPassword = async (e) => {
        //e.preventDefault();
        setLoading(true);
        const response = await fetch('/api/login/resetpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                password,
            }),
        });

        if (response.ok) {
            setLoading(false);
            ToastUtility.show({
                title: 'Reset password', content: 'Password reset successfully', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
            });
            navigate("/login");
        } else {
            setLoading(false);
            ToastUtility.show({
                title: 'Reset password', content: 'There was an error resetting password', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    };

    useEffect(() => {
        document.title = "Password Reset :: TriggerCraft";
    }, []);

    return (

        <div className="login-background">
            <div className="container">
                <div className="row">
                    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                        <div className="card my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <img src="/img/derwalt-logo.png" alt="Derwalt" height="120px" />
                                </div>
                                <h5 className="card-title text-center mt-5">Quote System Reset Password</h5>

                            <div className="mb-3">
                                <FormGroup>
                                    <Label>
                                        Password
                                        <span className="required-icon">*</span>
                                        <span id="errPassword" className="required-icon">
                                            {passwordError}
                                        </span>
                                    </Label>
                                    <Input
                                        type="password"
                                        bsSize="sm"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            fieldRequired(e.target.value, 'errPassword', 'required');
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Confirm Password
                                        <span className="required-icon">*</span>
                                        <span id="errConfirmPassword" className="required-icon">
                                            {confirmPasswordError}
                                        </span>
                                    </Label>
                                    <Input
                                        type="password"
                                        bsSize="sm"
                                        onChange={(e) => {
                                            passwordFieldRequired(e.target.value === password, "errConfirmPassword", "Password doesn't match");
                                        }}
                                    />
                                    <FormText color="muted">
                                        Password entered here should match the one in the above password
                                        field.
                                    </FormText>
                                </FormGroup>

                            </div>

                                <div className="text-end mb-3">
                                    <Button className="brand-btn" onClick={handleResetPassword}>Reset Password <i className="fa-solid fa-circle-chevron-right ms-2"></i></Button>
                                {/*<Button color='dark' size='sm' outline onClick={handleResetPassword}>Reset Password &nbsp;{!loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}</Button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default ResetPassword;
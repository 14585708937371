//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
//import { Home } from "./components/Home";
import { Login } from "./components/Login";

import { AdminDashboard } from "./components/Admin/Dashboard";
import { Categories } from "./components/Admin/Categories";
import { Companies } from "./components/Admin/Companies";
import { EventTypes } from "./components/Admin/EventTypes";
import { Prices } from "./components/Admin/Prices";
import { UserData } from "./components/Admin/UserData";
import { ForgotPassword } from "./components/ForgotPassword";
import { CustomerDashboard } from "./components/CustomerDashboard";
import { Register } from "./components/register";
import { Estimates } from "./components/Estimates";
import { RFQ } from "./components/Admin/RFQ";
import { Cities } from "./components/Admin/Cities";

const AppRoutes = [
  { index: true, element: <CustomerDashboard /> },
  { path: '/dashboard', element: <CustomerDashboard /> },
  { path: '/estimates/:estnumber', element: <Estimates /> },
  //{ path: '/fetch-data', element: <FetchData /> },
  //{ path: '/reset-password/:userId', element: <ResetPassword /> },
  //{ path: '/forgot-password', element: <ForgotPassword /> },
  //{ path: '/login', element: <Login /> }
];

export const AdminRoutes = [
  { index: true, element: <AdminDashboard /> },
  { path: '/categories', element: <Categories /> },
  { path: '/companies', element: <Companies /> },
  { path: '/event-types', element: <EventTypes /> },
  { path: '/prices', element: <Prices /> },
  { path: '/users', element: <UserData /> },
  { path: '/quotes', element: <RFQ /> },
  { path: '/cities', element: <Cities /> },

];


export default AppRoutes;

import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
//import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, InfiniteScroll, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, ExcelExport, Group, InfiniteScroll, Inject, Sort, Toolbar, Search, ToolbarItems } from '@syncfusion/ej2-react-grids';
//import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar } from '@syncfusion/ej2-react-treegrid';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, setAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Link } from 'react-router-dom';

export class Categories extends Component {

    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], orderData: [], loading: true, editModal: false, orderModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'], groupSettings: { columns: ["ParentName"], showDropArea: false },
            Id: 0,
            ParentId: 0,
            fParentId: 0,
            Name: '',
            LinkedToEventType: false,
            DisplayOrder: 0,
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            expandedRow: 0,
            expandedSubRow: 0,
        };
        this.toggle = this.toggle.bind(this);
        this.toggleOrderModal = this.toggleOrderModal.bind(this);
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    toggleOrderModal() {
        this.setState({
            orderModal: !this.state.orderModal
        });
    }

    componentDidMount() {
        document.title = "Categories Administration";
        this.loadData();
    }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({ Id: data.id, ParentId: data.parentId, Name: data.name, LinkedToEventType: data.linkedToEventType, DisplayOrder: data.displayOrder, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                ParentId: 0,
                Name: '',
                LinkedToEventType: false,
                DisplayOrder: 0,
                DateAdded: new Date(),
                AddedBy: 0,
                DateModified: new Date(),
                ModifiedBy: 0,
                Status: 0,
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;
        //valid &= fieldRequired(this.state.ParentId, 'tbParentIdError', '* required');
        valid &= fieldRequired(this.state.Name, 'tbNameError', '* required', 'tbName');
        //valid &= fieldRequired(this.state.LinkedToEventType, 'tbLinkedToEventTypeError', '* required');
        //valid &= fieldRequired(this.state.DisplayOrder, 'tbDisplayOrderError', '* required');

        if (valid) {
            const data = this.state.editData.find((item) => { return item.id == this.state.editId });
            if (this.state.editId > 0) { //do not overwrite the following fie when updating
                this.state.AddedBy = data.addedBy;
                this.state.DateAdded = data.dateAdded;
                this.state.Id = data.id;
                this.state.Status = data.status;
            }
            else {
                this.state.Status = 1;
            }
            this.saveData(this.state.editId);
            this.setState({ editModal: false });
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    capitalizeKeys = (obj) => {
        const newObj = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                newObj[capitalizedKey] = obj[key];
            }
        }
        return newObj;
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
        }
    }

    RenderParents = (props) => {
        const selectedCategoryIds = props.selectedCategories;
        const subcategory = this.state.editData.find(item => item.id === props.parentId);
        //console.log(subcategory)
        //const categoryTitles = categories.map(category => category.title).join(', ');
        return (
            <React.Fragment>
                {subcategory ? subcategory.name : <em>N/A</em>}
            </React.Fragment>
        );
    }

    renderDataTable(data, gridCommands, gridToolbar, commandClick, groupOptions) {

        //console.log(data)

        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} allowGrouping={true} groupSettings={groupOptions} >
                <ColumnsDirective>
                    <ColumnDirective field='Name' width='100' headerText="Name" />
                    <ColumnDirective field='ParentName' width='100' headerText="Parent Name" />
                    <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll, Group]} />
            </GridComponent>


            //<TreeGridComponent dataSource={data} treeColumnIndex={1} childMapping='Subcategories' editSettings={gridCommands} toolbar={gridToolbar}>
            //    <ColumnsDirective>
            //        <ColumnDirective field='Name' width='100' headerText="Name" isPrimaryKey={true} />
            //        <ColumnDirective field='' width='100' headerText="Parent" template={this.RenderParents} />
            //    </ColumnsDirective>
            //    <Inject services={[Edit, Toolbar]} />
            //</TreeGridComponent>
        );
    }

    renderTable(data) {
        return (
            <div className='card card-body p-0 rounded-0 border'>
                <Row className={`table m-0 pt-2 pb-1 align-items-center border-bottom`}>
                    <Col><strong>Name</strong></Col>
                    <Col className="text-end"><strong>Actions</strong></Col>
                </Row>
                <React.Fragment>
                    {data.length ? data.map((item, index) => (
                        <React.Fragment key={index}>
                            <Row className={`m-0 py-1 border-bottom`} >
                                <Col className="fw-normal" onClick={() => this.setState({ expandedRow: this.state.expandedRow === item.id ? 0 : item.id })}>
                                    <small><i className={`fa-solid fa-chevron-${this.state.expandedRow === item.id ? "down" : "right"}`} style={{cursor: "pointer"} } ></i>
                                    &nbsp;{item.name}</small>
                                </Col>
                                <Col className="text-end p-0">
                                    <i className="fa-solid mx-2 fa-pen text-muted" style={{ cursor: "pointer" }} onClick={() => this.editItem(item.id)}></i>
                                    <i className="fa-solid mx-2 fa-trash-can text-muted" style={{ cursor: "pointer" }} onClick={() => this.deleteItem(item.id)}></i>
                                </Col>
                            </Row>
                            {this.state.expandedRow === item.id &&
                                <>
                                    {item.subcategories.map((sub, subIndex) => (
                                        <Row key={subIndex} className={`m-0 ps-4 border-bottom`} >
                                            <Col className='ps-3'><small>&nbsp;{sub.name}</small></Col>
                                            <Col className='text-end p-0'>
                                                <i className="fa-solid mx-2 fa-pen text-muted" style={{ cursor: "pointer" }} onClick={() => this.editItem(sub.id)}></i>
                                                <i className="fa-solid mx-2 fa-trash-can text-muted" style={{ cursor: "pointer" }} onClick={() => this.deleteItem(sub.id)}></i>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                            }
                        </React.Fragment>
                    )) : (
                        <div className='my-5'>
                            <h4>No Records</h4>
                        </div>
                    )}
                </React.Fragment>
            </div>
        );
    }

    onItemDrop = (e) => {
        //console.log(e)
        const orderData = [];
        e.source.currentData.map((item, idx) =>
            orderData.push({ ...item, displayOrder: (idx + 1) })
        );
        this.setState({ orderData })
    }

    render() {
        let data = this.state.orderData;
        let gridData = this.state.sortData;
        let parents = this.state.editData.filter(item => item.parentId === 0);
        let children = this.state.editData.map(item => item.parentId !== 0 && { ...item, ParentName: parents.find(parent => parent.id == item.parentId)?.name }).filter(item => item.parentId);
        if (this.state.fParentId !== 0) {
            gridData = gridData?.filter(item => item.id === this.state.fParentId || (!this.state.fParentId && !item.id));
            data = data?.filter(item => item.parentId === this.state.fParentId || (!this.state.fParentId && !item.parentId));
        } else {
            data = parents;
        }
        //let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderTable(children, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand, this.state.groupSettings);
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderTable(gridData);
        //console.log(this.state, children)
        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={6}>
                            <h1>Categories Administration</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/admin" className="btn btn-outline-dark btn-sm me-2"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
                            <Button className="me-2 btn-dark" size="sm" data-recordid="0" onClick={this.toggleOrderModal}>Order Data <i className="fa-solid fa-sort ms-2"></i></Button>
                            <Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='card card-body shadow mb-3'>
                                <b>Filters</b>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <DropDownListComponent id='ddFilterId' name='ddFilterId' placeholder='Parent' dataSource={[{ name: "All", id: 0 }, ...parents]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.fParentId} change={e => this.setState({ fParentId: e.itemData ? e.itemData.id : 0 })} />
                                    </div>
                                </div>

                            </div>
                            {contents}
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Categories</ModalHeader>
                    <ModalBody>
                        {/*<div className='mb-3'>*/}
                        {/*  <Label for='ddParentId'>Parent</Label>*/}
                        {/*  <Input id='ddParentId' name='ddParentId' className='form-control' placeholder='Select Parent Id' type='select' required value={this.state.ParentId} onChange={e => this.setState({ ParentId: e.target.value })} />*/}
                        {/*</div>*/}

                        <div className='mb-3'>
                            <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} /> <div id='tbNameError' className='error-message' />
                        </div>

                        <div className='mb-3'>
                            <DropDownListComponent id='tbParentId' name='tbParentId' placeholder='Parent' dataSource={[{ name: "- Select -", id: 0 }, ...parents]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.ParentId} change={e => this.setState({ ParentId: e.itemData.id })} />
                            <div id='tbParentIdError' className='error-message' />
                        </div>

                        <div className='mb-3'>
                            <CheckBoxComponent id='cbLinkedToEventType' name='cbLinkedToEventType' type='checkbox' label='Linked To Event Type' checked={this.state.LinkedToEventType} onChange={e => this.setState({ LinkedToEventType: e.target.checked })} /> &nbsp; <div id='cbLinkedToEventTypeError' className='error-message' />
                        </div>

                        {/*<div className='mb-3'>*/}
                        {/*  <NumericTextBoxComponent id='tbDisplayOrder' name='tbDisplayOrder' placeholder='Display Order' floatLabelType='Auto' showClearButton={true} value={this.state.DisplayOrder} onChange={e => this.setState({ DisplayOrder: e.target.value })} /> <div id='tbDisplayOrderError' className='error-message' />*/}
                        {/*</div>*/}


                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
                    <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

                    <ModalBody>
                        <ListBoxComponent dataSource={data} allowDragAndDrop={true} fields={{ text: "name" }} drop={this.onItemDrop} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='success' size='sm' onClick={() => this.saveOrder()}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>
            </>

        );
    }

    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();

        try {
            const response = await fetch('api/categories', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                /*let newData = data.map(item => )*/
                const result = data.reduce((acc, item) => {
                    if (item.parentId === 0) {
                        acc.push({
                            ...item,
                            subcategories: [],
                            //UniqueId: `${item.id}`,
                        });
                    } else {
                        const parentIndex = acc.findIndex(parent => parent.id === item.parentId);
                        if (parentIndex !== -1) {
                            acc[parentIndex].subcategories.push({
                                ...item,
                            });
                        }
                    }
                    return acc;
                }, []);
                //console.log(data, result)
                this.setState({ editData: data, orderData: data, sortData: result, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        setAuthToken(getAuthToken(), new Date());
    }

    async saveData(dataId) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Id: this.state.Id, Id: this.state.Id, ParentId: this.state.ParentId, Name: this.state.Name, LinkedToEventType: this.state.LinkedToEventType, DisplayOrder: this.state.DisplayOrder, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

        try {
            const response = await fetch('api/categories', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Categories', content: 'The categories was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Categories', content: 'There was an error saving the categories!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async saveOrder() {
        //console.log(this.state.orderData)
        var bearer = 'Bearer ' + getAuthToken();
        const capitalizedOrderData = this.state.orderData.map(item => this.capitalizeKeys(item));
        const data = JSON.stringify(capitalizedOrderData);
        this.setState({ orderModal: false, loading: true })
        try {
            const response = await fetch("api/categories/SaveDisplayOrder", {
                method: "PUT",
                withCredentials: true,
                credentials: "include",
                headers: {
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                },
                body: data
            });

            if (response.ok) {
                ToastUtility.show({
                    title: 'Categories', content: 'The display order was successfully updated!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
                this.loadData();
            }

        } catch (e) {
            console.error(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/categories/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Categories', content: 'The categories was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Categories', content: 'There was an error deleting the categories!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}


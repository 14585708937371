/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Context = createContext();

const AppContext = ({ children }) => {
    
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);


    const handleNavigate = (route) => {
        navigate(route);
    }

    return (
        <Context.Provider
            value={{
                handleNavigate,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default AppContext;
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getAuthToken, getUserDetails, setAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { fieldRequired } from '../helpers/validation';


export class Estimates extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: false, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            UserId: 0,
            EventTypeId: 0,
            CityId: 0,
            EstNumber: 0,
            ProjectTitle: '',
            ProjectPax: 10,
            ProjectNumber: '',
            ProjectDescription: '',
            ProjectLocation: '',
            ProjectVenue: '',
            ProjectDate: new Date(),
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            RejectionReason: 0,
            TotalAmount: 0,
            GrandTotal: 0,
            VatAmount: 0,
            RejectionReasonOther: '',
            EventTypeList: [],
            CategoryList: [],
            PriceList: [],
            SelectedCategories: [],
            EstimateItems: [],
            CityList: [],
            IsSaved: false,
        };
        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    componentDidMount() {
        document.title = "Estimates Administration";
        this.loadData();
    }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.Id == id });
            this.setState({ Id: data.Id, CompanyId: data.CompanyId, UserId: data.UserId, EventTypeId: data.EventTypeId, CityId: data.CityId, EstNumber: data.EstNumber, ProjectTitle: data.ProjectTitle, ProjectPax: data.ProjectPax, ProjectNumber: data.ProjectNumber, ProjectDescription: data.ProjectDescription, ProjectLocation: data.ProjectLocation, ProjectDate: data.ProjectDate, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, RejectionReason: data.RejectionReason, RejectionReasonOther: data.RejectionReasonOther, });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                CompanyId: 0,
                CityId: 0,
                UserId: 0,
                EventTypeId: 0,
                EstNumber: 0,
                ProjectTitle: '',
                ProjectPax: 0,
                ProjectNumber: '',
                ProjectDescription: '',
                ProjectLocation: '',
                ProjectVenue: '',
                ProjectDate: new Date(),
                DateAdded: new Date(),
                AddedBy: 0,
                DateModified: new Date(),
                ModifiedBy: 0,
                Status: 0,
                RejectionReason: 0,
                RejectionReasonOther: '',
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;
        //valid &= fieldRequired(this.state.CompanyId, 'tbCompanyIdError', '* required');
        //valid &= fieldRequired(this.state.UserId, 'tbUserIdError', '* required');
        valid &= fieldRequired(this.state.EventTypeId, 'tbEventTypeIdError', '* required', 'ddEventTypeId');
        valid &= fieldRequired(this.state.CityId, 'tbCityIdError', '* required', 'ddCityId');
        valid &= fieldRequired(this.state.ProjectDate, 'tbProjectDateError', '* required', 'tbProjectDate');
        valid &= fieldRequired(this.state.ProjectTitle, 'tbProjectTitleError', '* required', 'tbProjectTitle');
        valid &= fieldRequired(this.state.ProjectPax, 'tbProjectPaxError', '* required', 'tbProjectPax');
        valid &= fieldRequired(this.state.ProjectLocation, 'tbProjectLocationError', '* required', 'tbProjectLocation');
        valid &= fieldRequired(this.state.ProjectVenue, 'tbProjectVenueError', '* required', 'tbProjectVenue');

        if (valid) {
            this.state.Status = 1;
            this.saveData(this.state.Id);
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.Id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.Id);
                break;
        }
    }

    calculateVat = (selectedCheckboxIds, priceData) => {
        try {
            const TotalAmount = this.calculateTotalPrice(selectedCheckboxIds, priceData)
            const vatAmount = TotalAmount * (15 / 100);
            const totalPrice = TotalAmount + vatAmount;
            //console.log(TotalAmount, vatAmount, totalPrice)
            return {
                VatAmount: vatAmount.toFixed(2),
                GrandTotal: totalPrice.toFixed(2)
            }

        } catch (error) {
            console.error('Error parsing priceData:', error);
            return 0;
        }

    }

    //calculateTotalPrice = (selectedCheckboxIds, pax, priceData) => {
    //    try {
    //        let selectedItems = priceData.filter(item => selectedCheckboxIds.includes(item.categoryId));
    //        selectedItems = selectedItems.map(item => ({ ...item, priceData: JSON.parse(item.priceData) }));
    //        //console.log(selectedItems)

    //        if (selectedItems) {
    //            let totalPrice = selectedItems.reduce((accumulator, item) => accumulator + (item.priceData.find(data => data.pax >= pax).price * pax), 0);
    //            if (totalPrice) {
    //                if (this.state.CityId) {
    //                    const value = this.state.CityList.find(item => item.id === this.state.CityId)
    //                    totalPrice = totalPrice * value.multiplier;
    //                }
    //                return totalPrice;
    //            } else {
    //                return 0;
    //            }
    //        } else {
    //            return 0;
    //        }
    //    } catch (error) {
    //        console.error('Error parsing priceData:', error);
    //        return 0;
    //    }
    //}

    calculateTotalPrice = (items, pax) => {
        let totalPrice = items.reduce((accumulator, item) => accumulator + item.Price, 0);

        if (this.state.CityId) {
            const value = this.state.CityList.find(item => item.id === this.state.CityId)
            totalPrice = totalPrice * value.multiplier;
            //console.log(items, cityId, totalPrice, value)
        }
        return totalPrice;
    }

    calculatePrice = (selectedCheckboxId, pax, priceData) => {
        //console.log(selectedCheckboxId, pax, priceData)

        try {
            let categoryData = priceData.find(item => item.categoryId === selectedCheckboxId);
            //console.log(categoryData)
            const parsedPriceData = JSON.parse(categoryData.priceData);
            if (categoryData) {
                const priceObject = parsedPriceData.find(item => item.pax >= pax);

                if (priceObject) {
                    return priceObject.price * this.state.projectPax;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } catch (error) {
            console.error('Error parsing priceData:', error);
            return 0;
        }

    }

    handleEstimateDownload = (event, priceData) => {
        event.stopPropagation();

        const elements = this.getElements(this.state.EstimateItems, this.state.ProjectPax);
        //console.log(elements);
        const estimate = {
            EstNumber: this.state.EstNumber,
            ProjectTitle: this.state.ProjectTitle,
            CompanyName: this.state.CompanyName,
            UserName: this.state.UserName,
            DateAdded: this.state.DateAdded,
            ProjectDate: this.state.ProjectDate,
            EventName: this.state.EventName,
            ProjectPax: this.state.ProjectPax,
            ProjectLocation: this.state.ProjectLocation,
            Elements: JSON.stringify(elements),
            Total: this.calculateTotalPrice(this.state.EstimateItems, this.state.ProjectPax),
        };
        //console.log(estimate, elements);
        this.downloadEstimate(estimate);
    };

    getElements = (estimates, pax) => {
        //console.log(estimates)
        const estimatesCategoryIds = new Set(estimates.map(item => item.CategoryId));

        const result = Object.entries(
            this.state.CategoryList
                .filter(category => estimatesCategoryIds.has(category.id))
                .reduce((acc, category) => {
                    const parentId = category.parentId > 0 ? category.parentId : category.id;
                    acc[parentId] = [...(acc[parentId] || []), category];
                    return acc;
                }, {})
        ).map(([parentId, categoryItems]) => {
            const categoryName = this.state.CategoryList.find(category => category.id === parseInt(parentId)).name;
            const totalPrice = categoryItems.reduce((sum, categoryItem) => {
                const categoryPrice = estimates
                    .filter(estimate => estimate.CategoryId === categoryItem.id)
                    .reduce((itemSum, estimate) => itemSum + estimate.Price, 0);
                return sum + categoryPrice;
            }, 0);

            return { Category: categoryName, Price: totalPrice };
        });

        return result;
    }

    //static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    //  return (
    //    <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
    //      <ColumnsDirective>
    //        <ColumnDirective field='UserId' width='100' headerText="UserId" />
    //        <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
    //      </ColumnsDirective>
    //      <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
    //    </GridComponent>
    //  );
    //}

    handleCategoryChange = (categoryId, priceData) => {
        let price = priceData.find(item => item.categoryId === categoryId);
        const parsedPriceData = JSON.parse(price.priceData);
        const priceObject = parsedPriceData.find(item => item.pax >= this.state.ProjectPax);
        //console.log(price, parsedPriceData, priceObject)
        const data = {
            EstimateId: 0,
            Pax: priceObject.pax,
            Price: priceObject.price * this.state.ProjectPax,
            Id: 0,
            PriceId: price.id,
            Status: 0,
            DateModified: new Date(),
            AddedBy: getUserDetails().id,
            CategoryId: categoryId,
            ModifiedBy: getUserDetails().id,
        }
        if (this.state.SelectedCategories.includes(categoryId)) {
            this.setState({
                SelectedCategories: this.state.SelectedCategories.filter(id => id !== categoryId),
                EstimateItems: this.state.EstimateItems.filter(item => item.CategoryId !== categoryId),
                IsSaved: false
            });
        } else {
            this.setState({
                SelectedCategories: [...this.state.SelectedCategories, categoryId],
                EstimateItems: [...this.state.EstimateItems, data],
                IsSaved: false
            });
        }
    };

    adjustPrices = async (selectedCategories, priceData, pax) => {
        //console.log(selectedCategories, priceData, pax)
        const adjustedEstimates = await Promise.all(selectedCategories.map(async (categoryId, index) => {
            const price = priceData.find(item => item.categoryId === categoryId);
            const parsedPriceData = JSON.parse(price.priceData);
            const priceObject = parsedPriceData.find(item => item.pax >= pax);
            const data = {
                EstimateId: this.state.EstimateItems[index].EstimateId,
                Pax: priceObject.pax,
                Price: priceObject.price * pax,
                Id: this.state.EstimateItems[index].Id,
                PriceId: price.id,
                Status: 0,
                DateModified: new Date(),
                AddedBy: getUserDetails().id,
                CategoryId: categoryId,
                ModifiedBy: getUserDetails().id,
            };
            return data;
        }));
        this.setState({
            EstimateItems: adjustedEstimates
        });

    };

    static renderEditor() {

        return (
            <>


            </>
        );
    }

    render() {
        /*let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Estimates.renderEditor();*/
        let categories = [];
        let prices = this.state.PriceList;
        if (!this.state.loading) {
            this.state.PriceList.forEach(item => {
                categories.push(item.categoryId);
            });
        }

        const childCategoryIds = new Set(categories);

        const childCategories = this.state.CategoryList.filter(category =>
            childCategoryIds.has(category.id)
        );

        const parentCategories = this.state.CategoryList.filter(category =>
            childCategories.find(item => item.parentId === category.id && item.parentId !== 2)
        );
        //if (this.state.EventTypeId !== 0) {
        //    prices = this.state.PriceList.filter(item => item.eventTypeId === this.state.EventTypeId);
        //}
        const matchedCategories = [...parentCategories, ...childCategories];
        matchedCategories.map(item => {
            if (item.parentId === 2 && !this.state.SelectedCategories.includes(item.id)) {
                this.handleCategoryChange(item.id, prices);
            }
        })

        //console.log(this.state, matchedCategories, parentCategories, childCategories)
        return (
            <>
                <div className="container mb-4">
                    <Row>
                        <Col xs={6}>
                            <h1>Estimate</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/" className="btn btn-outline-dark btn-sm me-2"><i className="far fa-circle-left me-2"></i>Dashboard</Link>
                            {/*<Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
                        </Col>
                    </Row>
                    <hr />

                    <div className="row">

                        <div className='mb-2 col-md-6'>
                            <TextBoxComponent id='tbProjectTitle' name='tbProjectTitle' placeholder='Project Title' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.ProjectTitle} onChange={e => this.setState({ ProjectTitle: e.target.value, IsSaved: false })} /> <div id='tbProjectTitleError' className='error-message' />
                        </div>

                        <div className='mb-2 col-md-3'>
                            <TextBoxComponent id='tbProjectNumber' name='tbProjectNumber' placeholder='Project Number' type='text' maxLength='150' floatLabelType='Always' showClearButton={true} value={this.state.ProjectNumber} onChange={e => this.setState({ ProjectNumber: e.target.value, IsSaved: false })} /> <div id='tbProjectNumberError' className='error-message' />
                        </div>

                        <div className='mb-3 col-md-3'>
                            <DatePickerComponent id='tbProjectDate' name='tbProjectDate' placeholder='Project Date' floatLabelType='Always' showClearButton={true} format='yyyy-MM-dd' value={this.state.ProjectDate} onChange={e => this.setState({ ProjectDate: e.target.value, IsSaved: false })} /> <div id='tbProjectDateError' className='error-message' />
                        </div>

                        <div className='mb-2 col-md-9'>
                            <DropDownListComponent id='ddEventTypeId' name="ddEventTypeId" floatLabelType='Always' showClearButton={false} placeholder="Event Type" dataSource={[{ title: "-SELECT-", id: 0 }, ...this.state.EventTypeList]} value={this.state.EventTypeId} fields={{ text: 'title', value: 'id' }} change={e => { this.setState({ EventTypeId: e.itemData.id, IsSaved: false }); this.adjustPrices(this.state.SelectedCategories, prices, this.state.ProjectPax) }} /> <div id='tbEventTypeIdError' className='error-message' />
                        </div>

                        <div className='mb-2 col-md-3'>
                            <NumericTextBoxComponent id='tbProjectPax' name='tbProjectPax' placeholder='Total Pax' decimals={0} format='n0' min={10} max={1000000} step={100} floatLabelType='Always' showClearButton={true} value={this.state.ProjectPax} onChange={e => { this.setState({ ProjectPax: e.target.value, IsSaved: false }); this.adjustPrices(this.state.SelectedCategories, prices, e.target.value) }} /> <div id='tbProjectPaxError' className='error-message' />
                        </div>
                    </div>


                    <div className='mb-2'>
                        <TextBoxComponent id='tbProjectDescription' name='tbProjectDescription' placeholder='Project Description' multiline={true} type='text' maxLength='750' floatLabelType='Always' showClearButton={true} value={this.state.ProjectDescription} onChange={e => this.setState({ ProjectDescription: e.target.value, IsSaved: false })} /> <div id='tbProjectDescriptionError' className='error-message' />
                    </div>

                    <div className="row">
                        <div className='mb-2 col-md-4'>
                            <TextBoxComponent id='tbProjectVenue' name='tbProjectVenue' placeholder='Project Venue' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.ProjectVenue} onChange={e => this.setState({ ProjectVenue: e.target.value, IsSaved: false })} /> <div id='tbProjectVenueError' className='error-message' />
                        </div>
                        <div className='mb-2 col-md-4'>
                            <TextBoxComponent id='tbProjectLocation' name='tbProjectLocation' placeholder='Location/Address' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={this.state.ProjectLocation} onChange={e => this.setState({ ProjectLocation: e.target.value, IsSaved: false })} /> <div id='tbProjectLocationError' className='error-message' />
                        </div>

                        <div className='mb-2 col-md-4'>
                            <DropDownListComponent id='ddCityId' name="ddCityId" floatLabelType='Always' showClearButton={false} placeholder="City" dataSource={[{ name: "-SELECT-", id: 0 }, ...this.state.CityList]} fields={{ text: 'name', value: 'id' }} value={this.state.CityId} change={e => this.setState({ CityId: e.itemData.id, IsSaved: false })} /> <div id='tbCityIdError' className='error-message' />
                        </div>
                    </div>

                    <h4 className="mb-0 pb-0 mt-4">Project Elements</h4><br />
                    <table className="table table-sm table-striped mb-3">
                        <thead>
                            <tr>
                                <th>Element</th>
                                {/*<th className="text-end">Cost</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                matchedCategories.sort((a, b) => a.displayOrder - b.displayOrder).map((item, index) => {
                                    const filteredItems = matchedCategories.filter(dataItem => dataItem.parentId === item.id && dataItem.parentId !== 2);
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                {item.parentId === 0 && <td className="pe-4">
                                                    {filteredItems.length > 0 ? <b>{item.name}</b> : <CheckBoxComponent checked={this.state.SelectedCategories.includes(item.id)} onChange={() => this.handleCategoryChange(item.id, prices)} label={item.name} />}
                                                </td>}
                                            </tr>
                                            {filteredItems.map((childItem, childIndex) => (
                                                <tr key={`child-${childIndex}`}>
                                                    <td className="pe-4 teal-checkbox" style={{ paddingLeft: '20px' }}>
                                                        <CheckBoxComponent checked={this.state.SelectedCategories.includes(childItem.id)} onChange={() => this.handleCategoryChange(childItem.id, prices)} label={childItem.name} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </tbody>
                        {/*<tfoot>*/}
                        {/*    <tr style={{ borderTop: "2px solid black" }}>*/}
                        {/*        <td className="text-end">TOTAL</td>*/}
                        {/*        <td className="text-end">{this.state.SelectedCategories.length ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateTotalPrice(this.state.SelectedCategories, prices)) : new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(0)}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <td className="text-end">VAT</td>*/}
                        {/*        <td className="text-end">{this.state.SelectedCategories.length ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateVat(this.state.SelectedCategories, prices).VatAmount) : new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(0)}</td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <td className="text-end">GRAND TOTAL</td>*/}
                        {/*        <td className="text-end" style={{ borderTop: "2px solid black", borderBottom: "4px double black" }} >{this.state.SelectedCategories.length ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateVat(this.state.SelectedCategories, prices).GrandTotal) : new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(0)}</td>*/}
                        {/*    </tr>*/}
                        {/*</tfoot>*/}
                    </table>
                    <div className='text-end mb-3'>
                        {this.state.IsSaved ? <Button onClick={(e) => this.handleEstimateDownload(e, prices)} className="btn btn-sm brand-btn me-2">View Estimate <i className="far fa-file-pdf ms-2"></i></Button> : ""}
                        <Button color="success" disabled={this.state.IsSaved} size="sm" onClick={this.saveItem}>Save Estimate <i className="far fa-check-circle ms-2"></i></Button>
                    </div>

                </div>
            </>

        );
    }

    async loadData() {

        const parts = window.location.href.split("/");
        const estnumber = parseInt(parts[parts.length - 1]);

        try {
            const response = await fetch('api/cities', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ CityList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/eventtypes', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ EventTypeList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/categories', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ CategoryList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/prices', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ PriceList: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        if (this.state.Id || estnumber) {
            const { handleNavigate } = this.context;
            //console.log(this.state.Id, estnumber, `api/estimates/${this.state.Id ? this.state.Id : estnumber}` )
            try {
                const response = await fetch(`api/estimates/${this.state.Id ? this.state.Id : estnumber}`, {
                    method: 'GET',
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    let data = await response.json();
                    //console.log(data)
                    if ((data.id && data.userId === getUserDetails().id) || (estnumber > 0 && data.userId === getUserDetails().id)) {
                        data = { ...data, estimateItems: JSON.parse(data.estimateItems) }

                        this.setState({ Id: data.id, CompanyId: data.companyId, EstimateItems: data.estimateItems, SelectedCategories: data.estimateItems.map(item => item.CategoryId), UserName: data.userName, CompanyName: data.companyName, EventName: data.eventName, UserId: data.id, EventTypeId: data.eventTypeId, CityId: data.cityId, EstNumber: data.estNumber, ProjectTitle: data.projectTitle, ProjectPax: data.projectPax, ProjectNumber: data.projectNumber, ProjectDescription: data.projectDescription, ProjectVenue: data.projectVenue, ProjectLocation: data.projectLocation, ProjectDate: data.projectDate, DateAdded: this.state.Id || estnumber ? data.dateAdded : new Date(), AddedBy: data.addedBy, DateModified: this.state.Id || estnumber ? data.dateModified : new Date(), ModifiedBy: data.modifiedBy, Status: data.status, RejectionReason: data.rejectionReason, RejectionReasonOther: data.rejectionReasonOther, loading: false });

                    } else {
                        handleNavigate(-1);
                    }
                }
                else {
                    console.log(response.status + ": " + response.statusText);
                    if (response.status === 401)
                        this.props.history.push("/login");
                    this.setState({ loading: false });
                }

            } catch (e) {
                console.error(e);
                this.setState({ loading: false });
            }
        }

        setAuthToken(getAuthToken(), new Date());
    }

    async saveData(dataId) {
        const { handleNavigate } = this.context;
        const userData = getUserDetails();
        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Id: this.state.Id, CompanyId: userData.companyId, EstimateItems: JSON.stringify(this.state.EstimateItems), UserId: userData.id, EventTypeId: this.state.EventTypeId, CityId: this.state.CityId, EstNumber: this.state.EstNumber, ProjectTitle: this.state.ProjectTitle, ProjectPax: this.state.ProjectPax, ProjectNumber: this.state.ProjectNumber, ProjectDescription: this.state.ProjectDescription, ProjectVenue: this.state.ProjectVenue, ProjectLocation: this.state.ProjectLocation, DateAdded: this.state.DateAdded, ProjectDate: this.state.ProjectDate, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, RejectionReason: this.state.RejectionReason, RejectionReasonOther: this.state.RejectionReasonOther, }

        try {
            const response = await fetch('api/estimates/EstimateData', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                let data = await response.json();
                //console.log(data)
                this.setState({ Id: data, loading: false });
                this.loadData();
                ToastUtility.show({
                    title: 'Estimates', content: 'The estimates was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
                this.setState({ IsSaved: true })
                //handleNavigate(-1);
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Estimates', content: 'There was an error saving the estimates!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async downloadEstimate(data) {
        this.setState({ loading: true, showError: false, showSuccess: false });

        try {
            const bearer = "Bearer " + localStorage.getItem("token");
            const response = await fetch('api/estimates/GeneratePdfDocument', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.item1;
                const MimeType = data.item2;
                const Filename = data.item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                //const LinkBtn = document.createElement("a");
                //LinkBtn.download = Filename;
                //LinkBtn.href = Base64String;
                //LinkBtn.click();
                let pdfWindow = window.open("");
                pdfWindow.document.write("<html<head><title>" + Filename + "</title><style>body{margin: 0px;}</style></head>");
                pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(FileData) + "'></embed></body></html>");
                pdfWindow.document.close();

                this.setState({ loading: false });
                ToastUtility.show({
                    title: 'Estimates', content: 'The estimate was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    window.location.href = "/";
                } else
                    this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.error(error)
            ToastUtility.show({
                title: 'Estimates', content: 'There was an error downloading the estimate!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/estimates/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Estimates', content: 'The estimates was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Estimates', content: 'There was an error deleting the estimates!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}


import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';
import { HtmlEditor, Image, Inject, Count, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { getAuthToken, setAuthToken, getUserDetails } from '../../helpers/authentication';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import "react-datepicker/dist/react-datepicker.css";
import { fieldRequired } from '../../helpers/validation';

export class RFQ extends Component {
        static options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    static endDate = moment().add(1, "days").format("yyyy-MM-DD");
    static startDate = moment().subtract(6, 'months').format("yyyy-MM-DD");
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Reject', buttonOption: { cssClass: 'e-flat', iconCss: 'e-circle-close e-icons' } },
            { type: 'Upload', buttonOption: { cssClass: 'e-flat', iconCss: 'e-export-pdf e-icons' } },
            { type: 'View', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
            //{ type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        const rejectionReasons = [
            { value: 0, text: '- Select -' },
            { value: 1, text: 'Unavailable Date' },
            { value: 2, text: 'Last-Minute Request' },
            { value: 3, text: 'Unspecified Details' },
            { value: 4, text: 'Incomplete Information' },
            { value: 5, text: 'Mismatched Services' },
            { value: 6, text: 'Previous Issues' },
            { value: 10, text: 'Other' },
        ];

        this.state = {
            editData: [], loading: true, editModal: false, uploadModal: false, rejectModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CompanyId: 0,
            UserId: 0,
            EventTypeId: 0,
            CityId: 0,
            EstNumber: 0,
            ProjectTitle: '',
            ProjectPax: 0,
            ProjectNumber: '',
            ProjectDescription: '',
            ProjectLocation: '',
            ProjectVenue: '',
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            estimateStartDate: RFQ.startDate, estimateEndDate: RFQ.endDate,
            dateRange: [{ startDate: new Date(RFQ.startDate), endDate: new Date(RFQ.endDate), key: "selection" }],
            estimateRange: [null, null],
            ModifiedBy: 0,
            Status: 0,
            fStatus: 0,
            RejectionReason: 0,
            TotalAmount: 0,
            GrandTotal: 0,
            VatAmount: 0,
            RejectionReasonOther: '',
            Mobile: '',
            EmailAddress: '',
            CategoryList: [],
            PriceList: [],
            EstimateItems: [],
            RejectionReasonsList: rejectionReasons,
            fileData: null,
            CityList: [],
        };
        this.toggle = this.toggle.bind(this);
        this.uploadToggle = this.uploadToggle.bind(this);
        this.rejectToggle = this.rejectToggle.bind(this);
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    uploadToggle() {
        this.setState({
            uploadModal: !this.state.uploadModal
        });
    }

    rejectToggle() {
        this.setState({
            rejectModal: !this.state.rejectModal
        });
    }

    componentDidMount() {
        document.title = "RFQ Administration";
        this.loadData();
    }

    updateDateRange = async (event) => {
        let startDate = event[0].toLocaleDateString('en-US', RFQ.options);
        let endDate = event[1].toLocaleDateString('en-US', RFQ.options);
        this.setState({ estimateStartDate: startDate, estimateEndDate: endDate }, e => {
            this.setDefaultDate();
        });
    }

    setDefaultDate = () => {
        let workDate = new Date(this.state.estimateStartDate);
        let dateRange = []

        while (workDate < new Date(this.state.estimateEndDate)) {
            let newDate = new Date(workDate.toDateString());
            dateRange.push(newDate);
            workDate.setDate(workDate.getDate() + 1);
        }
        this.setState({ dateRange: dateRange, loading: true });
        this.loadData()
    }

    calculateVat = (selectedCheckboxIds, priceData) => {
        try {
            const TotalAmount = this.calculateTotalPrice(this.state.EstimateItems, priceData)
            const vatAmount = TotalAmount * (15 / 100);
            const totalPrice = TotalAmount + vatAmount;
            //console.log(TotalAmount, vatAmount, totalPrice)
            return {
                VatAmount: vatAmount.toFixed(2),
                GrandTotal: totalPrice.toFixed(2)
            }

        } catch (error) {
            console.error('Error parsing priceData:', error);
            return 0;
        }

    }

    calculateTotalPrice = (items, priceData) => {
        try {
            let totalPrice = items.reduce((accumulator, item) => accumulator + item.Price, 0);

            if (this.state.CityId) {
                const value = this.state.CityList.find(item => item.id === this.state.CityId)
                totalPrice = totalPrice * value.multiplier;
            }
            return totalPrice;
        } catch (error) {
            console.error('Error parsing priceData:', error);
            return 0;
        }
    }

    calculatePrice = (selectedCheckboxId, pax, priceData) => {

        try {
            let categoryData = priceData.find(item => item.categoryId === selectedCheckboxId);
            const parsedPriceData = JSON.parse(categoryData.priceData);
            if (categoryData) {
                const priceObject = parsedPriceData.find(item => item.pax >= pax);

                if (priceObject) {
                    return priceObject.price * this.state.ProjectPax;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } catch (error) {
            console.error('Error parsing priceData:', error);
            return 0;
        }

    }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({ Id: data.id, CompanyId: data.companyId, UserId: data.userId, CompanyName: data.companyName, UserName: data.userName, EmailAddress: data.emailAddress, Mobile: data.mobile, ProjectDate: data.projectDate, EventName: data.eventName, EventTypeId: data.eventTypeId, CityId: data.cityId, EstNumber: data.estNumber, EstimateItems: data.estimateItems, ProjectTitle: data.projectTitle, ProjectPax: data.projectPax, ProjectNumber: data.projectNumber, ProjectDescription: data.projectDescription, ProjectLocation: data.projectLocation, ProjectVenue: data.projectVenue, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, rejectionReason: data.rejectionReason, RejectionReasonOther: data.rejectionReasonOther, });
        }
        else {
            this.setState({
                Id: 0,
                CompanyId: 0,
                CityId: 0,
                UserId: 0,
                EventTypeId: 0,
                EstNumber: 0,
                ProjectTitle: '',
                ProjectPax: 0,
                ProjectNumber: '',
                ProjectDescription: '',
                Mobile: '',
                EmailAddress: '',
                ProjectLocation: '',
                ProjectVenue: '',
                DateAdded: new Date(),
                AddedBy: 0,
                DateModified: new Date(),
                ModifiedBy: 0,
                Status: 0,
                RejectionReason: 0,
                RejectionReasonOther: '',
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    exportSurveyReport = (data, priceData) => {
        let stateData = [{
            ProjectTitle: data.ProjectTitle,
            EventName: data.EventName.trim(),
            EstNumber: data.EstNumber,
            CompanyName: data.CompanyName,
            UserName: data.UserName,
            ProjectNumber: data.ProjectNumber,
            ProjectPax: data.ProjectPax,
            DateAdded: new Date(data.ProjectDate),
            ProjectLocation: data.ProjectLocation,
            ProjectVenue: data.ProjectVenue,
            ProjectDescription: data.ProjectDescription,
        }]

        const categories = this.extractCategoryIds(this.state.EstimateItems);

        const childCategoryIds = new Set(categories);

        const childCategories = this.state.CategoryList.filter(category =>
            childCategoryIds.has(category.id)
        );

        const parentCategories = this.state.CategoryList.filter(category =>
            childCategories.find(item => item.parentId === category.id)
        );

        const matchedCategories = [...parentCategories, ...childCategories];
        if (stateData.length) {
            const filteredItems = stateData.map(item => ({
                ...item,
                EstNumber: "DW" + item.EstNumber.toString().padStart(5, '0'),
                City: this.state.CityId ? this.state.CityList.find(item => item.id === this.state.CityId).abbreviation : null,
                Total: this.calculateTotalPrice(this.state.EstimateItems, priceData).toFixed(2),
                Vat: this.calculateVat(categories, priceData).VatAmount,
                GrandTotal: this.calculateVat(categories, priceData).GrandTotal
            }));
            const tableData = [];

            for (const item of matchedCategories) {
                const filteredItems = matchedCategories.filter(dataItem => dataItem.parentId === item.id);
                if (filteredItems.length > 0) {
                    tableData.push({ Element: item.name, Cost: 0, Parent: true });
                } else if (item.parentId === 0) {
                    tableData.push({ Element: item.name, Cost: this.calculatePrice(item.id, this.state.ProjectPax, priceData), Parent: true });
                }

                for (const childItem of filteredItems) {
                    tableData.push({ Element: childItem.name, Cost: this.calculatePrice(childItem.id, this.state.ProjectPax, priceData), Parent: false });
                }
            }
            const finalData = { ...filteredItems[0], TableData: JSON.stringify(tableData) }
            
            this.downloadExcelDocument(finalData);
        } else {
            alert('No Data to Export')
        }
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };
    }

    uploadItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({ Id: data.id, CompanyId: data.companyId, UserId: data.userId, CompanyName: data.companyName, UserName: data.userName, EmailAddress: data.emailAddress, Mobile: data.mobile, ProjectDate: data.projectDate, EventName: data.eventName, EventTypeId: data.eventTypeId, CityId: data.cityId, EstNumber: data.estNumber, EstimateItems: data.estimateItems, ProjectTitle: data.projectTitle, ProjectPax: data.projectPax, ProjectNumber: data.projectNumber, ProjectDescription: data.projectDescription, ProjectLocation: data.projectLocation, ProjectVenue: data.projectVenue, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, rejectionReason: data.rejectionReason, RejectionReasonOther: data.rejectionReasonOther, });
        }
        this.setState({ editId: id, uploadModal: true });
    }

    rejectItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({ Id: data.id, CompanyId: data.companyId, UserId: data.userId, CompanyName: data.companyName, UserName: data.userName, EmailAddress: data.emailAddress, Mobile: data.mobile, ProjectDate: new Date(data.projectDate), EventName: data.eventName, EventTypeId: data.eventTypeId, CityId: data.cityId, EstNumber: data.estNumber, EstimateItems: data.estimateItems, ProjectTitle: data.projectTitle, ProjectPax: data.projectPax, ProjectNumber: data.projectNumber, ProjectDescription: data.projectDescription, ProjectLocation: data.projectLocation, ProjectVenue: data.projectVenue, DateAdded: new Date(data.dateAdded), AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, rejectionReason: data.rejectionReason, RejectionReasonOther: data.rejectionReasonOther, });
        }
        this.setState({ editId: id, rejectModal: true });
    }

    uploadExcelData = async (event) => {
        event.stopPropagation();
        var valid = true;
        valid &= fieldRequired(this.state.fileData, 'fileImportError', '* required');

        if (valid) {
            this.getBase64(this.state.fileData, (result) => {
                this.uploadQuote(result);
            });
        }
    }

    rejectEstimate = async (event) => {
        event.stopPropagation();
        var valid = true;
        valid &= fieldRequired(this.state.RejectionReason, 'ddRejectionReasonError', '* required', 'ddRejectionReason');

        if (valid) {
                this.saveData();
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Reject':
                this.rejectItem(args.rowData.id);
                break;
            case 'Upload':
                this.uploadItem(args.rowData.id);
                break;
            case 'View':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
        }
    }

    extractCategoryIds = (data) => {
        return data.map(item => item.CategoryId);
    }

    filteredCommands = (props) => {
                const shouldShowButton = props.status <= 2;
                
                return shouldShowButton ?
                    (<div className="text-end">
                        <i style={{ cursor: "pointer" }} onClick={() => this.rejectItem(props.id)} title="Reject" className="fa-solid fa-ban px-1 text-muted"></i>
                        <i style={{ cursor: "pointer" }} onClick={() => this.uploadItem(props.id)} title="Upload PDF" className="fa-regular fa-file-pdf px-1 text-muted"></i>
                       <i style={{cursor: "pointer"}} onClick={() => this.editItem(props.id)} title="View" className="fa-regular fa-eye px-1 text-muted"></i>
                    </div>) : 
                    (<div className="text-end">
                        <i style={{cursor: "pointer"}} onClick={() => this.editItem(props.id)} title="View" className="fa-regular fa-eye px-1 text-muted"></i>
                    </div>)
    }

    renderDataTable(data, gridCommands, gridToolbar, commandClick) {

        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} enableInfiniteScrolling={true} toolbar={gridToolbar} >
                <ColumnsDirective>
                    <ColumnDirective field='ProjectDate' width='50' headerText="Project Date" />
                    <ColumnDirective field='DateAdded' width='50' headerText="Date Requested" />
                    <ColumnDirective field='ProjectTitle' width='80' headerText="Project Title" />
                    <ColumnDirective field='UserName' width='60' headerText="Customer Name" />
                    <ColumnDirective field='EventName' width='50' headerText="Event Type" />
                    <ColumnDirective field='CompanyName' width='60' headerText="Company" />
                    <ColumnDirective headerText='Actions' width='50' headerTextAlign="Right" template={this.filteredCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
            </GridComponent>
        );
    }

    renderPriceTable(prices) {
        const categories = this.extractCategoryIds(this.state.EstimateItems);

        const childCategoryIds = new Set(categories);

        const childCategories = this.state.CategoryList.filter(category =>
            childCategoryIds.has(category.id)
        );

        const parentCategories = this.state.CategoryList.filter(category =>
            childCategories.find(item => item.parentId === category.id)
        );

        const matchedCategories = [...parentCategories, ...childCategories];
        
        return (
            <table className="table bordr table-sm table-striped mb-3">
                <thead>
                    <tr style={{ color: "teal" }}>
                        <th>Element</th>
                        <th className="text-end">Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        matchedCategories.sort((a, b) => a.displayOrder - b.displayOrder).map((item, index) => {
                            const filteredItems = matchedCategories.filter(dataItem => dataItem.parentId === item.id);
                            
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        {item.parentId === 0 && <><td className="pe-4">
                                            {filteredItems.length > 0 ? <b>{item.name}</b> : <span>{item.name}</span>}
                                        </td>
                                            <td className="text-end">
                                                {filteredItems.length > 0 ? "" : new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculatePrice(item.id, this.state.ProjectPax, prices))}
                                            </td></>}
                                    </tr>
                                    {filteredItems.map((childItem, childIndex) => (
                                        <tr key={`child-${childIndex}`}>
                                            <td className="pe-4 teal-checkbox" style={{ paddingLeft: '20px' }}>
                                                <span>{childItem.name}</span>
                                            </td>
                                            <td className="text-end" style={{ paddingLeft: '20px' }}>
                                                {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculatePrice(childItem.id, this.state.ProjectPax, prices))}
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })
                    }
                </tbody>
                <tfoot>
                    <tr style={{ borderTop: "2px solid black" }}>
                        <td className="text-end" style={{ color: "teal" }}>TOTAL</td>
                        <td className="text-end">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateTotalPrice(this.state.EstimateItems, prices))}</td>
                    </tr>
                    <tr>
                        <td className="text-end" style={{ color: "teal" }}>VAT</td>
                        <td className="text-end">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateVat(categories, prices).VatAmount)}</td>
                    </tr>
                    <tr>
                        <td className="text-end" style={{ color: "teal" }}>GRAND TOTAL</td>
                        <td className="text-end" style={{ borderTop: "2px solid black", borderBottom: "4px double black" }} >{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.calculateVat(categories, prices).GrandTotal)}</td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        let data = this.state.editData;
        let prices = this.state.PriceList;
        let statusData = [{ name: "All", id: 0 },
            { name: "Pending", id: 2 },
            { name: "Successful", id: 3 },
            { name: "Rejected", id: 4 }]
        if (this.state.fStatus !== 0) {
            data = data?.filter(item => item.status === this.state.fStatus || (!this.state.fStatus && !item.status));
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
        const uploadExcel = (e) => {
            const file = e.target.files[0];
            this.setState({ fileData: file });
        }
        //console.log(this.state)
        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={6}>
                            <h1>RFQ Administration</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Link to="/admin" className="btn btn-outline-dark btn-sm"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
                            {/*<Button className="me-2 btn-dark" size="sm" data-recordid="0" onClick={this.toggleOrderModal}>Order Data <i className="fa-solid fa-sort ms-2"></i></Button>*/}
                            {/*<Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='card card-body shadow mb-3'>
                                <b>Filters</b>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <DropDownListComponent id='ddFilterId' name='ddFilterId' placeholder='Status' dataSource={statusData} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.fStatus} change={e => this.setState({ fStatus: e.itemData ? e.itemData.id : 0 })} />
                                    </div>

                                    <div className='col-md-6'>
                                        <Label className="mb-0"><small className='text-muted fs-7'> Date range</small></Label>
                                        <br />
                                        <DatePicker
                                            className='border-style'
                                            selectsRange={true}
                                            placeholderText='Select Dates...'
                                            startDate={this.state.estimateRange[0]}
                                            endDate={this.state.estimateRange[1]}
                                            dateFormat='yyyy-MM-dd'
                                            onChange={(update) => [this.setState({ estimateRange: update }), update[1] && this.updateDateRange(update)]}
                                            isClearable={false} />
                                    </div>
                                </div>

                            </div>
                            {contents}
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.ProjectTitle}</ModalHeader>
                    <ModalBody>
                        <div className="row rfq-row">
                            <div className="city-label">
                                <span className="label">Event Type:</span>
                                <span className="value">{this.state.EventName}</span></div>
                            <div className="city-label">
                                <span className="label">Company:</span>
                                <span className="value">{this.state.CompanyName}</span></div>
                            <div className="city-label">
                                <span className="label">Project Number:</span>
                                <span className="value">{this.state.ProjectNumber}</span></div>
                            <div className="city-label">
                                <span className="label">Total Pax:</span>
                                <span className="value">{this.state.ProjectPax}</span></div>
                            <div className="city-label">
                                <span className="label">Project Date:</span>
                                <span className="value">{this.state.ProjectDate ? new Date(this.state.ProjectDate).toDateString() : ""}</span></div>
                            <div className="city-label">
                                <span className="label">Location:</span>
                                <span className="value">{this.state.ProjectLocation}</span></div>
                            <div className="city-label">
                                <span className="label">Venue:</span>
                                <span className="value">{this.state.ProjectVenue}</span></div>

                            {this.state.CityId ?
                                <div className="city-label">
                                    <span className="label">City:</span>
                                    <span className="city-value">{this.state.CityList.find(item => item.id === this.state.CityId).name}</span>
                                </div>
                                : null
                            }
                            <div className="city-label">
                                <span className="label">Project Description:</span>
                                <span className="value">{this.state.ProjectDescription}</span>
                            </div>
                        </div>

                        <h4 className="mb-0 pb-0 mt-4">Contact Details</h4>
                        <div className="row rfq-row">
                        <div className="city-label">
                            <span className="label">User Name:</span>
                            <span className="value">{this.state.UserName}</span></div>
                        <div className="city-label">
                            <span className="label">Email Address:</span>
                            <span className="value">{this.state.EmailAddress}</span></div>
                        <div className="city-label">
                            <span className="label">Mobile:</span>
                                <span className="value">{this.state.Mobile}</span></div>
                        </div>

                        <h4 className="mb-0 pb-0 mt-4">Project Elements</h4><br />
                        {this.renderPriceTable(prices)}

                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={() => this.exportSurveyReport(this.state, prices)}>Export Estimate <i className="far fa-file-excel ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.uploadModal} toggle={this.uploadToggle} className={this.props.className} scrollable size="md" backdrop="static">
                    <ModalHeader toggle={this.uploadToggle} close={<button className="close" onClick={this.uploadToggle}><i className="fas fa-times"></i></button>}>Upload Quote</ModalHeader>
                    <ModalBody>
                        <input placeholder='Browse documents ' type="file" accept='.pdf' onChange={(e) => uploadExcel(e)} />
                        <div id='fileImportError' className='error-message'></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.uploadToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='success' size='sm' onClick={e => this.uploadExcelData(e)}>Upload <i className='far fa-save-circle ms-2'></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.rejectModal} toggle={this.rejectToggle} className={this.props.className} scrollable size="md" backdrop="static">
                    <ModalHeader toggle={this.rejectToggle} close={<button className="close" onClick={this.rejectToggle}><i className="fas fa-times"></i></button>}>Reject Estimate</ModalHeader>
                    <ModalBody>
                        <div className='mb-3'>
                            <DropDownListComponent id='ddRejectionReason' name='ddRejectionReason' placeholder='Rejection Reason' dataSource={this.state.RejectionReasonsList} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.RejectionReason} change={e => this.setState({ RejectionReason: e.itemData.value })} /><div id='ddRejectionReasonError' className='error-message'></div>
                        </div>
                        {this.state.RejectionReason === 10 && 
                            <div className='mb-3'>
                                <TextBoxComponent id='tbRejectionReasonOther' name='tbRejectionReasonOther' placeholder='Rejection Reason Other' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.RejectionReasonOther} onChange={e => this.setState({ RejectionReasonOther: e.target.value })} /> <div id='tbRejectionReasonOtherError' className='error-message' />
                            </div>
}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.rejectToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='danger' size='sm' onClick={e => this.rejectEstimate(e)}>Reject Estimate <i className='far fa-save-circle ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    async loadData() {
        var bearer = 'Bearer ' + getAuthToken();

        try {
            const response = await fetch('api/cities', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                this.setState({ CityList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/categories', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CategoryList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        try {
            const response = await fetch('api/prices', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ PriceList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        try {
            const response = await fetch(`api/estimates/GetAllEstimates/${moment(this.state.estimateStartDate).format("yyyy-MM-DD")}/${moment(this.state.estimateEndDate).add(1, "days").format("yyyy-MM-DD") }`, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                let data = await response.json();
                data = data.map(item => ({ ...item, estimateItems: JSON.parse(item.estimateItems), dateAdded: new Date(item.dateAdded).toDateString(), projectDate: item.projectDate ? new Date(item.projectDate).toDateString() : "" }))
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        setAuthToken(getAuthToken(), new Date());
    }

    async downloadExcelDocument(data) {
        this.setState({ loading: true, showError: false, showSuccess: false });

        try {
            const bearer = "Bearer " + getAuthToken();
            const response = await fetch('api/estimates/GenerateExcelDocument', {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.blob();

                const contentDisposition = response.headers.get("content-disposition");
                const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
                const fileName = fileNameMatch
                    ? fileNameMatch[1].replace(/"/g, "")
                    : "unknown";
                const fileUrl = URL.createObjectURL(data);
                const LinkBtn = document.createElement("a");
                LinkBtn.download = fileName;
                LinkBtn.href = fileUrl;
                LinkBtn.click();

                this.setState({ loading: false });
                ToastUtility.show({
                    title: 'RFQ', content: 'The excel document was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            } else {
                if (response.status === 401) {
                    window.location.href = "/";
                } else
                    this.setState({ loading: false });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.error(error)
            ToastUtility.show({
                title: 'RFQ', content: 'There was an error downloading the excel document!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async uploadQuote(fileContents) {
        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { EstimateId: this.state.editId, FileContents: fileContents, FileName: this.state.fileData.name, AddedBy: getUserDetails().id, ModifiedBy: getUserDetails().id };
        
        try {
            const response = await fetch('api/quotes', {
                method: 'POST',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.setState({ uploadModal: false, loading: false });
                this.loadData();
                ToastUtility.show({
                    title: 'Quotes', content: 'The request was successfully sent!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Quotes', content: 'There was an error sending the request!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async saveData() {
        
        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Id: this.state.Id, CompanyId: this.state.CompanyId, EstimateItems: JSON.stringify(this.state.EstimateItems), UserId: this.state.UserId, EventTypeId: this.state.EventTypeId, CityId: this.state.CityId, EstNumber: this.state.EstNumber, ProjectTitle: this.state.ProjectTitle, ProjectPax: this.state.ProjectPax, ProjectNumber: this.state.ProjectNumber, ProjectDescription: this.state.ProjectDescription, ProjectVenue: this.state.ProjectVenue, ProjectLocation: this.state.ProjectLocation, DateAdded: this.state.DateAdded, ProjectDate: this.state.ProjectDate, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: 4, RejectionReason: this.state.RejectionReason, RejectionReasonOther: this.state.RejectionReasonOther, }
        console.log(data)
        try {
            const response = await fetch('api/estimates', {
                method: 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                let data = await response.json();
                this.setState({ rejectModal: false, loading: false });
                this.loadData();
                ToastUtility.show({
                    title: 'Estimates', content: 'The estimate was rejected successfully!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
                else if (response.status === 400) {
                    this.setState({ rejectModal: false, loading: false });
                    ToastUtility.show({
                        title: 'Estimates', content: 'There was an error rejecting the estimate!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
                    });
                }
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Estimates', content: 'There was an error rejecting the estimate!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}
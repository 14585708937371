import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, InfiniteScroll, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Label, Table } from 'reactstrap';
import * as math from 'mathjs';
import * as XLSX from 'xlsx';
import { getAuthToken, setAuthToken } from '../../helpers/authentication';
import { Select2 } from '../../helpers/Select2';
import '../../css/select2.css';
import { fieldRequired } from '../../helpers/validation';

export class Prices extends Component {

    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, uploadModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            CategoryId: 0,
            fCategoryId: 0,
            EventTypeId: 0,
            fEventTypeId: 0,
            Pax: 0,
            Price: 0,
            DateAdded: new Date(),
            AddedBy: 0,
            DateModified: new Date(),
            ModifiedBy: 0,
            Status: 0,
            CategoryList: [],
            EventTypeList: [],
            excelData: [],
            PriceData: [
                { id: 1, value: "", pax: 100, price: 0, cost: 0, formula: "" },
                { id: 2, value: "", pax: 500, price: 0, cost: 0, formula: "" },
                { id: 3, value: "", pax: 1000, price: 0, cost: 0, formula: "" },
                { id: 4, value: "", pax: 2000, price: 0, cost: 0, formula: "" },
                { id: 5, value: "", pax: 5000, price: 0, cost: 0, formula: "" },
                { id: 6, value: "", pax: 10000, price: 0, cost: 0, formula: "" },
                { id: 7, value: "", pax: 20000, price: 0, cost: 0, formula: "" },
                { id: 8, value: "", pax: 50000, price: 0, cost: 0, formula: "" },
                { id: 9, value: "", pax: 100000, price: 0, cost: 0, formula: "" },
            ],
            CityList: [],
            categoryData: [],
            SelectedItem: 0,
            UsdValue: 0,
        };
        this.toggle = this.toggle.bind(this);
        this.uploadToggle = this.uploadToggle.bind(this);
        this.uploadExcelData = this.uploadExcelData.bind(this)
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    uploadToggle() {
        this.setState({
            uploadModal: !this.state.uploadModal
        });
    }

    componentDidMount() {
        document.title = "Prices Administration";
        this.loadData();
    }

    capitalizeKeys(array) {
        //console.log(array)
        return array.map(obj => {
            const newObj = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                    newObj[capitalizedKey] = obj[key];
                }
            }
            return newObj;
        });
}

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            const dataArray = this.state.editData.filter(item => item.categoryId === data.categoryId);
            this.setState({ Id: data.id, CategoryId: data.categoryId, EventTypeId: data.eventTypeId, Pax: data.pax, Price: data.price, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, PriceData: data.priceData ? data.priceData : this.state.PriceData, categoryData: this.capitalizeKeys(dataArray) });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                CategoryId: 0,
                EventTypeId: 0,
                Pax: 0,
                Price: 0,
                DateAdded: new Date(),
                AddedBy: 0,
                DateModified: new Date(),
                ModifiedBy: 0,
                Status: 0,
                SelectedItem: 0,
                UsdValue: 0,
                PriceData: [
                    { id: 1, value: "", pax: 100, price: 0, cost: 0, formula: "" },
                    { id: 2, value: "", pax: 500, price: 0, cost: 0, formula: "" },
                    { id: 3, value: "", pax: 1000, price: 0, cost: 0, formula: "" },
                    { id: 4, value: "", pax: 2000, price: 0, cost: 0, formula: "" },
                    { id: 5, value: "", pax: 5000, price: 0, cost: 0, formula: "" },
                    { id: 6, value: "", pax: 10000, price: 0, cost: 0, formula: "" },
                    { id: 7, value: "", pax: 20000, price: 0, cost: 0, formula: "" },
                    { id: 8, value: "", pax: 50000, price: 0, cost: 0, formula: "" },
                    { id: 9, value: "", pax: 100000, price: 0, cost: 0, formula: "" },
                ],
                categoryData: [],
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;
        const isDuplicate = this.state.editData.find(item => item.categoryId === this.state.CategoryId && item.eventTypeId === this.state.EventTypeId);
        if (!isDuplicate || this.state.Id) {
            valid &= fieldRequired(this.state.CategoryId, 'ddCategoryIdError', '* required', 'ddCategoryId');
            //valid &= fieldRequired(this.state.EventTypeId, 'ddEventTypeIdError', '* required', 'ddEventTypeId');
            //valid &= fieldRequired(this.state.PriceData.every(item => item.price > 0), 'tbPriceError', '* required', 'tbPrice');
            //valid &= fieldRequired(this.state.Pax, 'tbPaxError', '* required');
            //valid &= fieldRequired(this.state.Price, 'tbPriceError', '* required');

            if (valid) {
                const data = this.state.editData.find((item) => { return item.id == this.state.editId });
                if (this.state.editId > 0) { //do not overwrite the following fie when updating
                    this.state.AddedBy = data.addedBy;
                    this.state.DateAdded = data.dateAdded;
                    this.state.Id = data.id;
                    this.state.Status = data.status;
                }
                else {
                    this.state.Status = 1;
                }
                const newData = this.state.categoryData.map(item => ({ ...item, PriceData: JSON.stringify(item.PriceData)}))
                this.saveData(this.state.editId, newData);
                this.setState({ editModal: false, SelectedItem: 0, });
            }
        } else {
            ToastUtility.show({
                title: 'Prices', content: 'You can\'t have Category and Event Type combination that already exist!', timeOut: 4000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    async uploadExcelData() {
        let valid = true;
        valid &= fieldRequired(this.state.excelData.length, "uploadData", "* required")
        if (valid) {
            this.setState({ uploadModal: false })

            let dataToUpload = this.state.excelData
            for (let i = 0; i < dataToUpload.length; i++) {
                try {
                    let category = dataToUpload[i]["Supplier Sub Category"] ? dataToUpload[i]["Supplier Sub Category"] : dataToUpload[i]["Supplier Category"];
                    let eventType = dataToUpload[i]["Event type"];
                    let EventTypeId = this.state.EventTypeList.find(item => item.title?.trim().toLowerCase() == eventType?.trim().toLowerCase());
                    let CategoryId = category?.includes("-") ? this.state.CategoryList.find(item => item.name?.trim().toLowerCase() == category.split("-")[1]?.toLowerCase()) : this.state.CategoryList.find(item => item.name?.trim().toLowerCase() == category?.toLowerCase());

                    var body = {
                        Id: this.state.Id,
                        EventNme: eventType,
                        EventTypeId: EventTypeId ? EventTypeId.id : 0,
                        CategoryName: CategoryId.name,
                        CategoryId: CategoryId.id || 0,
                        Company: dataToUpload[i].Supplier,
                        Pax: dataToUpload[i].capacity,
                        EmailFrom: dataToUpload[i]["email from"],
                        City: this.state.CityList.find(item => item.abbreviation == dataToUpload[i].city).abbreviation,
                        TotalBeforeVat: dataToUpload[i]["Total before tax"] ? dataToUpload[i]["Total before tax"] : 0,
                        AddedBy: this.state.AddedBy,
                        DateAdded: this.state.DateAdded,
                        ModifiedBy: this.state.ModifiedBy,
                        DateModified: this.state.DateModified,
                        Status: this.state.Status,
                    }
                    //console.log(body)
                    this.uploadPrices(0, body);
                } catch (error) {
                    console.error("Error uploading data: ", error);
                }
            }
        }
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Edit':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
        }
    }

    handleCheckboxChange = (data) => {

        this.setState({
            Price: data.price,
            Pax: data.pax,
            SelectedItem: data.id,
        });
    };

    RenderTable = (props) => {
        //console.log(props)

        return (
            <div>
                {props.priceData && props.priceData.map(item => (
                    <div className="row" key={item.id}>
                        <small className="col-md-4">{item.pax}</small>
                        {props.eventTypeId === 2002 ?
                            <small className="col-md-8 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', }).format(item.price)}</small>
                            :
                            <small className="col-md-8 text-end">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(item.price)}</small>
                        }
                    </div>
                ))}
            </div>
        );

    }

    renderDataTable(data, gridCommands, gridToolbar, commandClick) {
        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} enableInfiniteScrolling={true} height={500} toolbar={gridToolbar} cssClass='top-align-grid' >
                <ColumnsDirective>
                    <ColumnDirective field='CategoryName' width='70' headerText="Category" />
                    <ColumnDirective field='EventName' width='70' headerText="Event Type" />
                    <ColumnDirective field='' width='100' headerText="Pax & Price" template={this.RenderTable} />
                    <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
            </GridComponent>
        );
    }

    handleSelectChange(event) {
        //console.log(Array.from(event.target.selectedOptions).map(option => parseInt(option.value))[0]);
        const selectedValue = Array.from(event.target.selectedOptions).map(option => parseInt(option.value)); this.setState({ CategoryId: selectedValue[0] });
    }

    filterItemsWithChildren = (data) => {
        const parentIdsWithChildren = new Set(data.filter(item => item.parentId !== 0).map(item => item.parentId));
        return data.filter(item => !parentIdsWithChildren.has(item.id));
    };

    handlePriceChange(rowIndex, price, value) {
            const updatedPriceData = [...this.state.categoryData];

        const updatePriceData = (item, value, price) => {
            const newFormula = value || item.PriceData[rowIndex].formula;
            item.PriceData[rowIndex] = {
                ...item.PriceData[rowIndex],
                value: this.state.PriceData[rowIndex].value,
                cost: price || item.PriceData[rowIndex].cost || this.state.PriceData[rowIndex].cost,
                price: value
                    ? (item.EventTypeId === 2002
                        ? this.calculateValue(newFormula, item.PriceData[rowIndex].pax, item.PriceData[rowIndex].cost, this.state.UsdValue || 0.055)
                        : this.calculateValue(newFormula, item.PriceData[rowIndex].pax, item.PriceData[rowIndex].cost)
                    )
                    : (item.EventTypeId === 2002
                        ? this.calculateValue(newFormula, item.PriceData[rowIndex].pax, price, this.state.UsdValue || 0.055)
                        : this.calculateValue(newFormula, item.PriceData[rowIndex].pax, price)
                    ),
                formula: newFormula
            };
        };

        if (updatedPriceData.length > 0) {
            updatedPriceData.forEach(item => {
                updatePriceData(item, value, price);
            });
        }

            //console.log(updatedPriceData, rowIndex)

            this.setState({ categoryData: updatedPriceData });
    }

    handleFormulaChange(rowIndex, value, eventId, price) {
            const updatedPriceData = [...this.state.categoryData];

        const existingItemIndex = updatedPriceData.findIndex(item => item.EventTypeId === eventId);

        const updatePriceData = (item, value, price) => {
            const newFormula = value || item.PriceData[rowIndex].formula;
            item.PriceData[rowIndex] = {
                ...item.PriceData[rowIndex],
                value: this.state.PriceData[rowIndex].value,
                cost: price || item.PriceData[rowIndex].cost || this.state.PriceData[rowIndex].cost,
                price: value
                    ? (item.EventTypeId === 2002
                        ? this.calculateValue(newFormula, item.PriceData[rowIndex].pax, item.PriceData[rowIndex].cost, this.state.UsdValue || 0.055)
                        : this.calculateValue(newFormula, item.PriceData[rowIndex].pax, item.PriceData[rowIndex].cost)
                    )
                    : (item.EventTypeId === 2002
                        ? this.calculateValue(newFormula, item.PriceData[rowIndex].pax, price, this.state.UsdValue || 0.055)
                        : this.calculateValue(newFormula, item.PriceData[rowIndex].pax, price)
                    ),
                formula: newFormula
            };
        };

            const createNewItem = () => ({
                Id: 0,
                AddedBy: 0,
                CategoryId: this.state.CategoryId,
                DateAdded: new Date(),
                dateModified: new Date(),
                EventTypeId: eventId,
                ModifiedBy: 0,
                Pax: 0,
                Price: 0,
                PriceData: [
                    { id: 1, value: "", pax: 100, price: 0, cost: 0, formula: "" },
                    { id: 2, value: "", pax: 500, price: 0, cost: 0, formula: "" },
                    { id: 3, value: "", pax: 1000, price: 0, cost: 0, formula: "" },
                    { id: 4, value: "", pax: 2000, price: 0, cost: 0, formula: "" },
                    { id: 5, value: "", pax: 5000, price: 0, cost: 0, formula: "" },
                    { id: 6, value: "", pax: 10000, price: 0, cost: 0, formula: "" },
                    { id: 7, value: "", pax: 20000, price: 0, cost: 0, formula: "" },
                    { id: 8, value: "", pax: 50000, price: 0, cost: 0, formula: "" },
                    { id: 9, value: "", pax: 100000, price: 0, cost: 0, formula: "" },
                ],
                Status: 1
        });


        if (existingItemIndex !== -1) {
            if (value) {
                updatePriceData(updatedPriceData[existingItemIndex], value, price);
            } else {
                updatePriceData(updatedPriceData[existingItemIndex], '', price);
            }
        } else if(value){
            updatedPriceData.push(createNewItem());
        }

        if (updatedPriceData.find(item => item.EventTypeId == eventId)) {

        updatedPriceData.find(item => item.EventTypeId == eventId).PriceData[rowIndex] = { ...this.state.PriceData[rowIndex], formula: value, price: eventId === 2002 ? this.calculateValue(value, this.state.PriceData[rowIndex].pax, this.state.PriceData[rowIndex].cost, this.state.UsdValue || 0.055) : this.calculateValue(value, this.state.PriceData[rowIndex].pax, this.state.PriceData[rowIndex].cost) };
        }

            //console.log(updatedPriceData, rowIndex)

            this.setState({ categoryData: updatedPriceData });
    }
    //renderRow = (item, rowIndex) => (
    //    <tr key={rowIndex}>
    //        <td className="text-end">{item.pax}</td>
    //        <td>
    //            <NumericTextBoxComponent
    //                showClearButton={true}
    //                enableRtl={true}
    //                value={item.cost}
    //                onChange={(e) => this.handlePriceChange(rowIndex, e.target.value)}
    //            />
    //        </td>
    //        {this.state.EventTypeList.map((colItem, colIndex) => (
    //            <td key={colIndex}>
    //                {this.renderMultiplierTextBox(rowIndex, colItem, colIndex)}
    //                <br />
    //                {this.renderSmallText(colItem, item, rowIndex)}
    //            </td>
    //        ))}
    //    </tr>
    //);

    //handlePriceChange = (rowIndex, newValue) => {
    //    const newPrice = parseFloat(newValue);
    //    const updatedCostData = this.state.PriceData.map((costItem, index) => {
    //        if (index === rowIndex) {
    //            return { ...costItem, cost: newPrice };
    //        }
    //        return costItem;
    //    });
    //    this.handleMultiplierChange(rowIndex, null, this.state.EventTypeId, newPrice);
    //    this.setState({ PriceData: updatedCostData });
    //};

    //renderMultiplierTextBox = (rowIndex, colItem, colIndex) => (
    //    <TextBoxComponent
    //        placeholder='Formula'
    //        type='text' 
    //        maxLength='250'
    //        showClearButton={true}
    //        value={this.getMultiplierValue(rowIndex, colItem)}
    //        onChange={(e) => this.handleMultiplierChange(rowIndex, e.target.value, colItem.id, colItem)}
    //    />
    //);

    getMultiplierValue = (rowIndex, colItem) => {
        let exists = this.state.categoryData.findIndex(item => item.EventTypeId === colItem?.id);
        //console.log( rowIndex, exists, this.state.categoryData[exists].PriceData[rowIndex]?.formula)
        
        if (this.state.categoryData.length && exists !== -1) {
            return this.state.categoryData[exists].PriceData[rowIndex]?.formula;
        }
        return this.state.PriceData[rowIndex].formula;
    };

    handleChange = (rowIndex, value) => {
        let updatedPriceData = [...this.state.PriceData];
        
        updatedPriceData[rowIndex] = {...this.state.PriceData[rowIndex], value}
        this.setState({ PriceData: updatedPriceData })
    };



    //renderSmallText = (colItem, item, rowIndex) => (
    //    <>
    //        {colItem.id !== 2002 ?
    //            <small>
    //                {(this.state.categoryData.length && this.state.categoryData.findIndex(item => item.EventTypeId === colItem.id) >= 0 && item.pax && item.price) ?
    //                    new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(this.calculateValue(this.getMultiplierValue(rowIndex, colItem))) :
    //                    "R0"} p/p
    //            </small>
    //            :
    //            <small>{(this.state.categoryData.length && this.state.categoryData.findIndex(item => item.EventTypeId === colItem.id) >= 0 && item.pax && item.price) ?
    //                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((item.price / item.pax) * this.getMultiplierValue(rowIndex, colItem)) :
    //                "$0"} p/p
    //            </small>
    //        }
    //    </>
    //);

    isDescendant = (categoryId, parentId) => {
        const findCategory = (categories, categoryId, parentId) => {
            for (const category of categories) {
                if (category.id === categoryId) {
                    return category.parentId === parentId || findCategory(categories, category.parentId, parentId);
                }
            }
            return false;
        };

        return findCategory(this.state.CategoryList, categoryId, parentId);
    };

    calculateValue(formula, pax, cost, usd) {
        if (formula && typeof formula === 'string') {
            try {
                if (usd) {
                    const Cost = cost;
                    const Pax = pax;
                    const Usd = usd;

                    const replacedFormula = formula.replace(/cost/gi, Cost).replace(/pax/gi, Pax).replace(/usd/gi, Usd);

                    const result = math.evaluate(replacedFormula);
                    /*console.log(result, replacedFormula, formula, usd, Pax, Cost)*/
                    return result / pax;
                } else { 
                    const Cost = cost;
                    const Pax = pax;

                    const replacedFormula = formula.replace(/cost/gi, Cost).replace(/pax/gi, Pax);

                    const result = math.evaluate(replacedFormula);

                    return result / pax;
                }
            } catch (error) {
                console.error('Error calculating value:', error.message);
                //alert("incorrect formula")
                return 0;
            }
        }

        return 0;
    }

    render() {
        let data = this.state.editData;
        if (this.state.fCategoryId !== 0) {
            data = data?.filter(item =>
                item.categoryId === this.state.fCategoryId ||
                (item.categoryId !== undefined && this.isDescendant(item.categoryId, this.state.fCategoryId))
            );
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
        const parentIds = new Set(this.state.CategoryList.map(item => item.parentId));
        const filteredData = this.state.CategoryList.filter(item => !parentIds.has(item.id) || !parentIds.has(item.parentId));

        const uploadExcel = (e) => {
            const file = e.target.files[0];
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsBinaryString(e.target.files[0])

                reader.onload = (e) => {
                    const contents = e.target.result;
                    const wb = XLSX.read(contents, { type: "binary" });
                    const sheetName = wb.SheetNames[0];
                    const sheet = wb.Sheets[sheetName];
                    const newData = XLSX.utils.sheet_to_json(sheet);
                    this.setState({ excelData: newData })

                }

            } else {
                console.error('Invalid file type');
            }
        }

        const parentCategories = this.state.CategoryList.filter(item => item.parentId === 0)
        let mapedData = parentCategories.map(item => { return { text: item.name, children: this.state.CategoryList.filter(sub => sub.parentId === item.id).map(sub => { return { text: sub.name, id: sub.id } }) } })
        console.log(this.state)
        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={4}>
                            <h1>Prices Administration</h1>
                        </Col>
                        <Col xs={8} className="text-end align-self-center">
                            <Link to="/admin" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Admin Dashboard</Link>
                            {/*<Button className="mx-1" color="success" size="sm" data-recordid="0" onClick={e => this.uploadToggle()}> Upload Prices</Button>*/}
                            <Button className="brand-btn" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className='card card-body shadow mb-3'>
                                <b>Filters</b>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <DropDownListComponent id='ddFilterId' name='ddFilterId' placeholder='Parent Category' dataSource={[{ name: "All", id: 0 }, ...parentCategories]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.fCategoryId} change={e => this.setState({ fCategoryId: e.itemData ? e.itemData.id : 0 })} />
                                    </div>
                                </div>

                            </div>
                            {contents}
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Prices</ModalHeader>
                    <ModalBody>
                        {/*<div className='row'>*/}
                            <div className='mb-3 col-md'>
                                {this.state.Id ? <TextBoxComponent id='ddCategoryId' name='ddCategoryId' placeholder='Category' type='text' maxLength='250' floatLabelType='Always' disabled showClearButton={false} value={this.state.CategoryList.find(item => item.id === this.state.CategoryId).name} />
                                    : <><label className="mb-0 text-dark">Select Category</label><br/>
                                        <Select2 data={[{ text: "- Select -", id: 0 }, ...mapedData]} className="form-control form-control-sm" defaultValue={this.state.CategoryId} onSelect={e => { this.setState({ CategoryId: Array.from(e.target.selectedOptions).map(option => parseInt(option.value))[0] }); } } /> {/*<DropDownListComponent id='ddCategoryId' name='ddCategoryId' placeholder='Category' dataSource={[{ name: "- Select -", id: 0 }, ...filteredData]} fields={{ text: 'name', value: 'id' }} floatLabelType='Always' value={this.state.CategoryId} change={e => this.setState({ CategoryId: e.itemData.id })} />*/}
                                      </>
                                }
                                <div id='ddCategoryIdError' className='error-message' />
                            {/*</div>*/}
                        </div>
                        <h4>Editing Infrastructure</h4>
                        <div className="alert alert-info" role="alert">
                            <small>Please fill in all the <strong>cost</strong> values before adding the formula</small><br />
                            Formula Values:<br />
                            <b>COST </b> will use the value from the Cost column<br />
                            <b>PAX </b> will use the value from the PAX column<br />
                            <b>USD </b> will use the current USD conversion value<br />

                        </div>
                        {this.state.CategoryId ? <div className="d-flex mb-3 text-end col-md-12"><NumericTextBoxComponent showClearButton={true} floatLabelType='Always' placeholder='USD Value' enableRtl={true} value={this.state.UsdValue} onChange={e => this.setState({ UsdValue: e.target.value })} /></div> : ""}
                        {this.state.CategoryId ? 
                            <table className="table table-sm table-striped">
                                {/*{this.renderTableHeader()}*/}
                                {/*{this.renderTableBody()}*/}
                            <thead>
                                <tr className="bg-secondary">
                                    <th style={{ width: "80px" }}>PAX</th>
                                    <th style={{ width: "14%" }}>Cost</th>
                                    <th style={{ width: "10%" }}>Price</th>
                                    {this.state.EventTypeList.map((item, index) => (
                                        <th key={index} style={{ width: "14%" }}>{item.title}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                    {this.state.PriceData.map((item, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="text-end">{item.pax}</td>
                                            <td>
                                                <NumericTextBoxComponent showClearButton={true} enableRtl={true} value={item.cost} onChange={e => {

                                                    const newPrice = parseFloat(e.target.value);
                                                    const updatedCostData = this.state.PriceData.map(costItem => {
                                                        if (costItem.id === item.id) {
                                                            return { ...costItem, cost: newPrice };
                                                        }
                                                        return costItem;
                                                    });
                                                    this.handlePriceChange(rowIndex, newPrice)
                                                    this.setState({ PriceData: updatedCostData });
                                                }} />
                                            </td>
                                            <td key={rowIndex}>
                                                <TextBoxComponent
                                                    placeholder='Pricing from Sheet'
                                                    type='text'
                                                    maxLength='250'
                                                    showClearButton={true}
                                                    value={item.value}
                                                    onBlur={(e) => {
                                                        this.handleChange(rowIndex, e.target.value);
                                                    }}
                                                />
                                            </td>
                                            {this.state.EventTypeList.map((colItem, colIndex) => {
                                                const formula = this.getMultiplierValue(rowIndex, colItem);
                                                const value = colItem.id !== 2002 ? this.calculateValue(formula, item.pax, item.cost) : this.calculateValue(formula, item.pax, item.cost, this.state.UsdValue || 0.055);

                                                return (
                                                    <td key={colIndex}>
                                                        <TextBoxComponent
                                                            placeholder='Formula'
                                                            type='text'
                                                            maxLength='250'
                                                            showClearButton={true}
                                                            value={formula}
                                                            onBlur={(e) => {
                                                                this.handleFormulaChange(rowIndex, e.target.value || "", colItem.id, null);
                                                                this.setState({ EventTypeId: colItem.id });
                                                            }}
                                                        />
                                                        <br />
                                                        <small>
                                                            {colItem.id !== 2002
                                                                ? this.state.categoryData.length &&
                                                                    this.state.categoryData.findIndex((item) => item.EventTypeId === colItem.id) >= 0 &&
                                                                    item.pax &&
                                                                    item.cost
                                                                    ? new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' }).format(value) + " p/p"
                                                                    : "R0 pp"
                                                                : this.state.categoryData.length &&
                                                                    this.state.categoryData.findIndex((item) => item.EventTypeId === colItem.id) >= 0 &&
                                                                    item.pax &&
                                                                    item.cost
                                                                    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) + " p/p"
                                                                    : "$0 pp"}
                                                        </small>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                            </tbody>
                            </table> : ""}


                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.uploadModal} toggle={this.uploadToggle} className={this.props.className} scrollable size="md" backdrop="static">
                    <ModalHeader toggle={this.uploadToggle} close={<button className="close" onClick={this.uploadToggle}><i className="fas fa-times"></i></button>}>Upload Prices</ModalHeader>
                    <ModalBody>
                        <input placeholder='Browse excel documents ' type="file" accept='.xlsx, .xls' onChange={(e) => uploadExcel(e)} />
                        <div id='uploadData' className='text-danger'></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.uploadToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='success' size='sm' onClick={e => this.uploadExcelData()}>Upload <i className='far fa-save-circle ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>

        );
    }

    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();

        try {
            const response = await fetch('api/cities', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                //console.log(data)
                this.setState({ CityList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/eventtypes', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ EventTypeList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/categories', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CategoryList: data });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        try {
            const response = await fetch('api/prices', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                let data = await response.json();
                //console.log(data)
                data = data.map(item => ({ ...item, priceData: JSON.parse(item.priceData) }));
                //data = { ...data, priceData: JSON.parse(data.priceData) };
                this.setState({ editData: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
        setAuthToken(getAuthToken(), new Date());
    }

    async saveData(dataId, priceData) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Data: JSON.stringify(priceData) };
        //var data = { Id: this.state.Id, Id: this.state.Id, CategoryId: this.state.CategoryId, EventTypeId: this.state.EventTypeId, Pax: this.state.Pax, Price: this.state.Price, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, PriceData: JSON.stringify(this.state.PriceData) }

        try {
            const response = await fetch('api/prices', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Prices', content: 'The prices was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Prices', content: 'There was an error saving the prices!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async uploadPrices(dataId, data) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        //var data = { Id: this.state.Id, Id: this.state.Id, CategoryId: this.state.CategoryId, EventTypeId: this.state.EventTypeId, Pax: this.state.Pax, Price: this.state.Price, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, PriceData: JSON.stringify(this.state.PriceData) }

        try {
            const response = await fetch('api/pricedata', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'PriceData', content: 'The priceData was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Prices', content: 'There was an error saving the priceData!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/prices/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Prices', content: 'The prices was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Prices', content: 'There was an error deleting the prices!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}

